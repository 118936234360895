import React from 'react';
import { Checkbox, makeStyles, Box } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import Link from './Link.component';
import Text from './text.component';
import { useStaticQuery } from 'gatsby';
import { useAppContext } from '../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'grey',
    marginRight: '0.375rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
  },
  termsML: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '.5rem',
      marginRight: '.5rem',
    },
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    '& a': {
      fontWeight: '400 !important',
      fontSize: '14px !important',
      lineHeight: '21px !important',
    },
  }
}));

const TermsAndConditions = ({ termsAccepted, setTermsAccepted, disableCheck }) => {
  const { user: { isLoggedIn } } = useAppContext();
  const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          termsAndPolicy {
            policyLink
            termsLink
            loggedInTermsLink
          }
        }
      }
    }
    `
  );
  const classes = useStyles();

  const { termsLink, loggedInTermsLink, policyLink } = site.siteMetadata.termsAndPolicy;
  const termsLinkHref = isLoggedIn ? loggedInTermsLink : termsLink;

  return (
    <Box className={classes.container}>
      <Checkbox
        checked={termsAccepted}
        onChange={() => setTermsAccepted(!termsAccepted)}
        checkedIcon={<CheckBoxOutlinedIcon />}
        classes={{ root: classes.root }}
        color="primary"
        disableRipple
        required
        disabled={disableCheck}
      />
      <Text className={classes.termsML}>
        Acepto los{' '}
        <Link inverted href={termsLinkHref} target="_blank" rel="noreferrer" download>
          términos y condiciones
        </Link>{' '}
        y{' '}
        <Link inverted href={policyLink} target="_blank" rel="noreferrer" download >
          <div style={{ overflowWrap: 'break-word' }} >
            políticas de privacidad.
          </div>
        </Link>
      </Text>
    </Box>
  );
};

export default TermsAndConditions;
