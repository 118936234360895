

import { DELETE_CREDIT_LINE } from '../constants';

export default function (budgetDispatch) {
  budgetDispatch({
    type: DELETE_CREDIT_LINE,
    data: null
  });
}
