import { makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import BudgetReservationData from '../../components/budget/BudgetReservationData.component';
import BudgetVehicleData from '../../components/budget/BudgetVehicleData.component';
import Text from '../../components/common/text.component';
import Layout from '../../components/layouts/layout.component';
import BudgetClientForm from './BudgetClientForm.container';
import { useAppContext } from '../../context/AppContext';
import BudgetBackButton from '../../components/budget/BudgetBackButton.component';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_BUDGET_LEGALS } from '../../queries/getLegalsBudget';
import LoadingModal from '../../components/common/LoadingModal.component';
import { SEND_BUDGET } from '../../mutations/sendBudget';
import { EMAIL_TYPE } from '../../utils/constants';
import { isResale } from '../../api/authorization';

const PAGE_TITLE = 'Entrega de usado';
const META_DESCRIPTION = 'Entrega de usado';
const BACK_TEXT = 'Volver al presupuesto';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0',
    margin: '0',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: '2rem',
    paddingRight: '0 !important',
    [theme.breakpoints.down('sm')]: {
      padding: '.3rem !important',
      margin: '0 !important',
      [theme.breakpoints.landscape()]: {
        paddingLeft: '0',
        marginLeft: '0',
        display: 'grid !important',
      }
    },
  },
  item: {
    width: '100%',
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
    paddingRight: '4rem !important',
    [theme.breakpoints.down('md')]: {
      padding: '0 !important',
      margin: '0 !important',
      width: '-webkit-fill-available',
    },
  },
  legal: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '1rem !important',
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '21px',
      marginLeft: '.6rem',
      marginRight: '.6rem',
      marginBottom: '2rem',
      alignItems: 'left',
    },
  },
  link: {
    marginTop: '3.9rem',
    marginLeft: '4.7rem',
    '& :hover': {
      color: theme.palette.primary.dark,
    },
  },
  mainTitle: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '26px',
    lineHeight: '39px',
    color: '#262626',
    marginBottom: '1.5rem',
    marginTop: '.8rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginLeft: '.6rem',
      marginRight: '.6rem',
    },
  }
}));

const getUserCarsValue = (usedCars) => {
  let usedCarsValue = 0;
  for (let car of usedCars){
    const price = car.quote.prices.find(e => e.type === 'estimated').amount;
    usedCarsValue += price;
  }
  return usedCarsValue;
};

const BudgetSummaryContainer = ({ location, pageContext, isMobile }) => {
  const { budget, user } = useAppContext();
  const { creditLine, usedCars } = budget;
  const { isLoggedIn, profile: { firstname, lastname, email, phone } = {} } = user;

  const { loading: loadingbudgetLegals, data: budgetLegalsResponse } = useQuery(
    GET_BUDGET_LEGALS,
    {
      variables: {
        options: {
          hasTakingCar: Boolean(usedCars.length),
          hasFinancing: Boolean(creditLine.id),
        },
      },
    }
  );

  const [sendBudget] = useMutation(SEND_BUDGET);


  const sendEmailAndBudget = (emailData, budget) => {
    return sendBudget({
      variables: { ...emailData, budget, options: {
        hasTakingCar: Boolean(usedCars.length),
        hasFinancing: Boolean(creditLine.id),
      }, emailType:EMAIL_TYPE.RESERVATION },
    });
  };

  const handleStartPaymentClick = (data, budgetBody) => sendEmailAndBudget(data, budgetBody);

  const loadingData = loadingbudgetLegals;

  const classes = useStyles();

  const {
    car: {
      brand,
      model,
      version,
      is0km,
      km,
      fuel,
      year,
      images = [],
      selectedStock,
      stock = [],
      vehiclePrice,
    },
    creditLine: {
      financedAmount,
      condition,
      bank,
      nameComercial,
      financialCosts,
    },
    deliveryCosts,
    finalPrice
  } = budget;
  const stockFind = stock.find(vehicle => vehicle.id === selectedStock);

  const budgetVehicleData = {
    brandName: brand?.name,
    modelName: model?.name,
    versionName: version?.name,
    is0km,
    km,
    fuel,
    year,
    image: images[0],
    color: stockFind?.color?.name || '',
    observation: stockFind?.observation || '',
  };
  const usedCarsValue = getUserCarsValue(usedCars);
  const budgetReservationData = {
    usedCars,
    financedAmount,
    financialCosts,
    condition,
    bank,
    nameComercial,
    deliveryCosts,
    vehiclePrice,
    usedCarsValue,
    finalPrice,
    is0km
  };

  let clientData = {};

  if(isLoggedIn) {
    clientData.name = firstname;
    clientData.lastName = lastname;
    clientData.email = email;
    clientData.phone = phone;
  }

  const isWithoutPay = location.pathname.includes('sinsena');

  const isResaleAndWithoutPay =  isResale && isWithoutPay;

  return !loadingData ? (
    <Layout
      title={PAGE_TITLE}
      description={META_DESCRIPTION}
      location={location}
      pageContext={pageContext}
    >
      <BudgetBackButton text={BACK_TEXT} marginLeft={ isMobile ? '1.5rem' : '3rem'} />
      <Grid
        className={classes.container}
        container
        direction="row"
      >
        {isResaleAndWithoutPay ? <></> :
          <Grid className={classes.mainTitle} item xs={24} md={12} >
          ¡Ahora sí!{isMobile && <br/>} Reservá tu auto dejando el pago de una seña
          </Grid>
        }
        <Grid item xs={12} md={6} className={classes.item}>
          <BudgetVehicleData budgetVehicleData={budgetVehicleData} />
          <BudgetReservationData
            budgetReservationData={budgetReservationData}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.item}>
          <BudgetClientForm
            disableClientFormFields={isLoggedIn}
            clientData={clientData}
            onStartPaymentClick = {handleStartPaymentClick}
            isResaleAndWithoutPay = {isResaleAndWithoutPay}
          />
        </Grid>
        <Grid item xs={12} className={classes.legal}>
          <Text light>
            {budgetLegalsResponse.getBudgetLegals.map((text) => (
              <div key={text} >{text}</div>
            ))}
          </Text>
        </Grid>
      </Grid>
    </Layout>
  ) : (
    <LoadingModal />
  );
};

export default BudgetSummaryContainer;
