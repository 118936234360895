import { Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import SaleCarFormContainer from '../sell-your-car/SaleCarForm.container'
import SaleLoadingModal from '../../components/sell-your-car/SaleLoadingModal.component'
import ModalExitPrompt from '../../components/common/ModalExitPrompt.component'
import SaleCarSuccess from '../../components/sell-your-car/SaleCarSuccess.component'
import Text from '../../components/common/text.component'
import BudgetQuoteCarContainer from './BudgetQuoteCar.container'
import BudgetBackButton from '../../components/budget/BudgetBackButton.component'
import { useAppContext } from '../../context/AppContext'
import ResponsiveStatusModal from '../../components/common/ResponsiveStatusModal.component'
import setFormHasData from '../../context/budget/actions/setFormHasData'

const BACK_TEXT = 'Volver al presupuesto'
const BUDGET_TITLE = 'Entrega de usado'
const SELL_YOUR_CAR_BUTTON = 'Cotizar'
const SALE_CAR_FORM_TITLE = 'Empecemos por conocer tu usado.'
const SALE_CAR_FORM_SUBTITLE =
  'Completá los datos para poder realizar una propuesta.'
const MESSAGE_ERROR = 'Lo sentimos...'
const PRIMARY_BTN_ERROR = 'Volver'
const RESERVE_LEYEND_ERROR = <>
En este momento no tenemos una cotización para el auto que elegiste.
<br />
<br />
Podés cargar otro usado o intentarlo 
nuevamente más tarde.
</>
const titleModalPrompt = '¿Confirmás que querés salir?'
const subtitleModalPrompt = 'Si salís, no se guardará la información que cargaste.'

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'grid',
    placeContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.space.vertical.mobile} ${theme.space.horizontal.mobile}`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.space.vertical.desktop} ${theme.space.horizontal.desktop}`,
    },
  },
  content: {
    margin: '0 auto',
  },
  item: {
    width: '100%',
  },
  title: {
    marginBottom: '1rem',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight:'2.813rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      marginTop: '3.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '3rem',
    },
  },  
  sectionErrorLeyendPrimary: {
    marginTop: '1.5rem',
    marginBottom: '2rem',
    textAlign: 'center',
    padding:'0 1.85rem 0 1.85rem'
  },
  sectionErrorLeyendSecondary: {
    marginBottom: '4rem',
    textAlign: 'center',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}))

const BudgetQuoteContainer = ({ isMobile }) => {
  const classes = useStyle()
  const { budget, budgetDispatch } = useAppContext();
  const { car, creditLine, usedCars, editCar } = budget;
  const [formData, setFormData] = useState(!editCar ? {
    car: {
      brand: {
        id: null,
        name: null,
      },
      model: {
        id: null,
        name: null,
      },
      version: {
        id: null,
        name: null,
      },
      year: null,
      mileage: null,
      condition: null,
    },
    client: {
      name: null,
      lastName: null,
      email: null,
      phone: null,
    },
  }
    :
    {
      car: {
        brand: {
          id: editCar.car.idBrand,
          name: editCar.car.marca,
        },
        model: {
          id: editCar.car.idModel,
          name: editCar.car.modelo,
        },
        version: {
          id: editCar.car.idVersion,
          name: editCar.car.version,
        },
        year: editCar.car.year,
        mileage: editCar.carInfo[0].value,
        condition: editCar.car.state,
      },
      quote: {
        prices: null,
        date: null,
      },
      client: {
        name: null,
        lastName: null,
        email: null,
        phone: null,
      },
    }
  );

  const [successSendSaleCarForm, setSuccessSendSaleCarForm] = useState(false)
  const steps = [
    {
      order: 0,
      label: 'Entrega de usado ',
      html: (
        <SaleCarFormContainer
          sellYourCarButton={SELL_YOUR_CAR_BUTTON}
          saleCarFormTitle={SALE_CAR_FORM_TITLE}
          saleCarFormSubtitle={SALE_CAR_FORM_SUBTITLE}
          previousQuote={formData.car}
          onNextStep={() => handleNextStep()}
          onSaveCarData={(data) => handleSaveCarData(data)}
          onFormEmpty={(isEmpty) => handleFormEmpty(isEmpty)}
          addToBudget={true}
        />
      ),
    },
    {
      order: 1,
      label: 'Entrega de usado',
      html: (
        <BudgetQuoteCarContainer
          car={formData.car}
          onBackStep={() => handleBackStep()}
          onOpenModal={() => setActiveModal({ type: 'error' })}
        />
      ),
    },
  ]

  const [currentStep, setCurrentStep] = useState({
    order: 0,
    label: 'Datos de tu auto',
    html: <SaleCarFormContainer />,
  })
  const [activeModal, setActiveModal] = useState({
    type: 'none',
  })

  const handleFormEmpty = (isEmpty) => {
    setFormHasData(budgetDispatch, !isEmpty)
  }

  const modals = {
    loading: {
      html: (
        <SaleLoadingModal
          open={true}
          title={'¡Ya casi, no te vayas!'}
          subtitle={'Estamos preparando tu solicitud.'}
        />
      ),
    },
    error: {
      html: (
        <ResponsiveStatusModal
          success={false}
          loading={false}
          isMobile={isMobile}
          open={true}
          errorMessage={MESSAGE_ERROR}
          errorLegend={RESERVE_LEYEND_ERROR}
          errorTextBtn={PRIMARY_BTN_ERROR}
          onErrorBtnClick={() => {
            setActiveModal({ type: 'none' })
          }}
          onClose={() => {
            setActiveModal({ type: 'none' })
          }}
        />
      ),
    },
    none: {
      html: <></>,
    },
  }

  const handleNextStep = () => {
    setCurrentStep((prevCurrentStep) => steps[prevCurrentStep.order + 1])
  }

  const handleBackStep = () => {
    setCurrentStep((prevCurrentStep) => steps[prevCurrentStep.order - 1])
  }

  const handleSaveCarData = (data) => {
    setFormData({
      ...formData,
      car: data,
    })
  }

  return (
    <>
      {!isMobile && (
        <BudgetBackButton text={BACK_TEXT}/>
      )}
      <Grid
        className={classes.container}
        container
        direction="column"
      >
        {successSendSaleCarForm ? (
          <SaleCarSuccess />
        ) : (
          <>
            <Grid className={classes.title} item>
              <Text>{BUDGET_TITLE}</Text>
            </Grid>
            <Grid className={classes.item} item>
              {steps[currentStep.order].html}
            </Grid>
          </>
        )}
      </Grid>
      {modals[activeModal.type].html}
    </>
  )
}

export default BudgetQuoteContainer
