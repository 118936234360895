import React from 'react'
import Chip from '../common/Chip.component'
import { Box, makeStyles } from '@material-ui/core'
import { capitalizeEachWord, capitalizeText } from '../../utils/typography.utils'
import { formaterNumber } from '../../utils/currency.utils'
import noCarImage from '../../assets/images/no_car_img.png';
import Image from '../common/Image.component';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '1rem 1.5rem',
    border: `0.0625rem solid ${theme.palette.grayScale.g300}`,
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '.8rem .5rem .8rem .5rem',
      marginLeft: '.6rem',
      marginRight: '.6rem',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '8.0625rem',
      padding: '1rem 1.5rem',
    },
  },
  cardContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
    '& .text-container': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1.5rem',
      [theme.breakpoints.down('md')]: {
        marginLeft: '1rem',
      }
    },
    '& .flex-row': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      color: theme.palette.grayScale.g600,
      marginTop: '0.5rem',
    },
    '& .li': {
      margin: '0 0.5rem',
    },
    '& img': {
      [theme.breakpoints.down('sm')]: {
        height: '3.875rem',
        width: '5.75rem',
      },
      [theme.breakpoints.up('md')]: {
        width: '7.5rem',
        height: '5.6281rem',
      },
    },
    '& #title-1': {
      fontWeight: '700',
      lineHeight: '2.0625rem',
      color: theme.palette.grayScale.g500,
      marginBottom: '0.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.375rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.size.large,
      },
    },
    '& #title-2': {
      fontweight: '400',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
      marginBottom: '1rem',
      marginRight: '0.5rem',
      [theme.breakpoints.down('md')]: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#262626',
      },
    },
  },
  chipRoot: {
    height: 'initial',
    '& span':{
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      height: '1.5rem',
      borderRadius: '0.3125rem',
      color: theme.palette.button.active,
      background: theme.palette.grayScale.g200,
      fontWeight: '400',
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  image: {
    width: '7.5rem',
    height: '5.6281rem',
    backgroundSize: 'contain'
  },
  noImage: {
    width: '7.5rem',
    height: '5.6281rem',
    backgroundSize: '18.75rem'
  },
}))

const BudgetVehicleData = ({ budgetVehicleData }) => {
  const classes = useStyle()
  const {
    brandName = '',
    modelName = '',
    versionName,
    is0km,
    km,
    fuel,
    image,
    color,
    year,
    observation,
  } = budgetVehicleData
  return (
    <Box className={classes.container}>
      <div className={classes.cardContainer}>
        {image ? (<Image image={image} className={classes.image}/>) : (<Image image={noCarImage} className={classes.noImage}/>)}
        <div className="text-container">
          <label id="title-1">
            {capitalizeText(brandName)} {capitalizeText(modelName)}
          </label>
          <span>
            <label id="title-2">{versionName}</label>
            {observation && (
              <Chip
                id="chip"
                label={observation}
                classes={{ root: classes.chipRoot }}
              />
            )}
          </span>
          <span className="flex-row">
            {is0km ? (
              <>
                <label>0 Km</label> <label className="li">•</label>{' '}
                {fuel && <><label>{capitalizeText(fuel)}</label> <label className="li">•</label></>}{' '}
                {color && <label>{capitalizeEachWord(color)}</label>}
              </>
            ) : (
              <>
                {year && <><label>{year}</label> <label className="li">•</label></>}{' '}
                {km && <><label>{formaterNumber(km)} Km</label> <label className="li">•</label></>}{' '}
                {fuel && <><label>{fuel}</label> <label className="li">•</label></>}{' '}
                {color && <label>{capitalizeEachWord(color)}</label>}
              </>
            )}
          </span>
        </div>
      </div>
    </Box>
  )
}

export default BudgetVehicleData
