import gql from 'graphql-tag';

export const GET_BUDGET= gql`
  query GetBudget($id: String) {
    getBudget(id: $id) {
        client_first_name
        image_car
        catalog_marca
        catalog_model
        catalog_version
        catalog_anio
        color{
            id
            name
        }
        kilometres
        fuel
        hasCreditLine
        hasUsedCar
        observation
    }
  }
`;
