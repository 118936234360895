import React from 'react';
import Layout from '../../components/layouts/layout.component';
import BudgetDetailContainer from './BudgetDetail.container';
import queryString from 'query-string';
import BackArrow from '../../components/header/BackArrow.component';


const PAGE_TITLE = 'Presupuesto - Carmuv'; // TODO: validar
const META_DESCRIPTION = ''; //TODO: validar
//const DETAIL_BREADCRUMB_INDEX = 1;

const BudgetContainer = (props) => {
  const { location } = props;
  const query = queryString.parse(location.search);
  const pageContext = {
    ...props.pageContext,
  };

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader={false}
      description={META_DESCRIPTION}
    >
      <BackArrow entityName='budget'/>
      <BudgetDetailContainer
        location={location}
        carId={query.productId}
        stockId={query.stockId}/>
    </Layout>
  );
};

export default BudgetContainer;
