
import gql from 'graphql-tag';

export const GET_BUDGET_CAR = gql`
query GetBudgetCar(
  $catalogId: String
  ) {
    budgetCar(catalogId: $catalogId) {
        userBasedPrice
        images
        id
        brand {
            id
            name
        }
        model {
            id
            name
        }
        resalePrice
        netSalePrice
        version {
            id
            name
        }
        company {
            id
            logo
            name
        }
        carLot {
            id
            companyId
            name
        }
        branchOfficeInfo {
          id
          name
        }
        colors {
            id
            name
            color
        }
        stock {
            price
            discount
            id
            observation
            localResalePrice
            resale_price
            originCountry
            color {
                id
                name
                color
            }
            estado
            vin
            stateReseller
            statePublic
        }
        km
        currency_symbol
        currencyName
        foreign_currency_symbol
        subversion
        is0km
        year
        patent
        localNetSalePrice
        localListPrice
        localBonification
        countryAcronym
        local_flete_alistaje
        local_accessory_1
        local_accessory_2
        factorCurrencyLocal
    }
}`;
