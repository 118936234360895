import { useQuery } from '@apollo/react-hooks'
import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import BudgetQuoteCar from '../../components/budget/BudgetQuoteCar.component'
import LoadingModal from '../../components/common/LoadingModal.component'
import { GET_QUOTATION } from '../../queries'

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
  },
}))

const BudgetQuoteCarContainer = ({ car, onBackStep, onOpenModal }) => {
  const classes = useStyle()
  const {
    loading: loadingGetQuotation,
    error: errorGetQuotation,
    data: dataGetQuotation,
  } = useQuery(GET_QUOTATION, {
    variables: {
      info: {
        interior: car?.condition,
        kms: Number(car?.mileage),
        vehicle: {
          model: car?.model.id,
          brand: car?.brand.id,
          version: car?.version.id,
          year: car?.year,
        },
      },
    },
  })
  if (loadingGetQuotation) return <LoadingModal />
  if (errorGetQuotation){
    onOpenModal()
    onBackStep()
  }

  if(dataGetQuotation?.carQuote){
    dataGetQuotation.carQuote.car.idModel = car?.model.id;
    dataGetQuotation.carQuote.car.idBrand = car?.brand.id;
    dataGetQuotation.carQuote.car.idVersion = car?.version.id;
    dataGetQuotation.carQuote.car.state = car?.condition;
  }

  return (
    <Box className={classes.container}>
      <BudgetQuoteCar
        car={car}
        quoteData={dataGetQuotation}
        onBackStep={onBackStep}
      />
    </Box>
  )
}

export default BudgetQuoteCarContainer
