import React from 'react';
import ResponsiveStatusModal from '../../common/ResponsiveStatusModal.component';
import { makeStyles } from '@material-ui/styles';

import {
  MESSAGE_LOADING_SUCCES,
  RESERVE_LEYEND_LOADING_SUCCES
} from '../../../containers/budget/constants';

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    paddingBottom:'3.75rem',
  },[theme.breakpoints.down('sm')]: {
    padding: '0rem 2rem',
    margin: '0.6rem',
    }
}));

const BudgetLoadingReservationModal = ({ setOpenModal, isMobile }) => {
  const classes = useStyles();
  return (
    <ResponsiveStatusModal
      loading={true}
      isMobile={isMobile}
      open={true}
      loadingMessage={MESSAGE_LOADING_SUCCES}
      loadingLegend={RESERVE_LEYEND_LOADING_SUCCES}
      onClose={() => {setOpenModal(false);}}
      containerClassName={classes.containerLoading}
    />
  );
};

export default BudgetLoadingReservationModal;
