import gql from 'graphql-tag';

export const GET_CREDIT_LINES = gql`
query GetCreditLines(
    $carLotId: UUID,
    $brandId: UUID,
    $modelId: UUID,
    $vehicleType: String,
    $catalogId: UUID,
    $sortBy: Int,
    $manufactureYear: Int
  ) {
    getCreditLines(vehicleInfo: {
    carLotId: $carLotId
    brandId: $brandId
    modelId: $modelId
    vehicleType: $vehicleType
    catalogId: $catalogId
    sortBy: $sortBy
    manufactureYear: $manufactureYear
  }) {
      id
      name
      nameComercial
      description
      tipoTasa
      tipoVenta
      bank
      tipoMMV
      tipoCreditoID
      tipoCredito
      lines {
        id
        tna
        quantity
        cft
        tir
        capMax
        quebrantoPorc
        porcMaxFinance
        expenses
        valQuoteProm
        valorPrimeraCuota
        gastosFijos
      }
    }
}
`;
