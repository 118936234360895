import React from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'
import StepConnector from '@material-ui/core/StepConnector'
import Text from '../common/text.component'
import Button from '../common/button.component'
import { formatPrice, formaterNumber } from '../../utils/currency.utils'
import { transformCondition } from '../../utils/carCondition'
import clsx from 'clsx'
import { useAppContext } from '../../context/AppContext'
import { SET_ADD_USED_CAR, SET_EDIT_USED_CAR, UPDATE_USED_CAR } from '../../context/budget/constants'
import { goToPresupuesto } from '../../api/navigation'

const TITLE = 'Esta es nuestra propuesta para vos'
const BODY_TEXT = 'Valor de cotización*'
const FOOTER_TEXT = '*Cotización sujeta a peritaje'
const BUTTON_TEXT = 'Sumar al presupuesto'

const useStyle = makeStyles((theme) => ({
  budgetQuoteContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '23.875rem',
      gap: '0.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      width: '38.5rem',
    },
    textAlign: 'center',
    margin: '0 auto',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1rem',
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  horizontalContainerBrandModel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems:'start',
    },
  },
  buttonContainer: {
    paddingTop: '2rem',
    paddingBottom: '0.5rem',
    justifyContent: 'flex-end',
    width: '100%',
  },
  buttonAddToBudget: {
    width: '15.3rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize:'1rem',
    },
  },
  budgetTitle: {
    width: '100%',
    marginBottom: '1rem',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    background: '#F7F7F7',
    borderRadius: '1rem',
    marginTop: '1rem',
    padding: '1.5rem',
    gap: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2.5rem',
    },
  },
  textTitle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.25rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'18.688rem',
      textAlign:'initial',
      paddingRight:'3rem',
    }
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.25rem',
  },
  brandAndModel: {
    marginRight: '0.5rem',
  },
  responsiveText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  quoteFooter: {
    marginTop: '0.5rem',
    paddingLeft: '1.5rem',
  },
  separator: {
    width: '100%',
  },
  generalMargin: {
    marginTop: '1rem',
    marginBottom: '1rem',
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    }
  },
  blackText: {
    color: theme.palette.grayScale.g500,
    marginLeft: '0.5rem',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
}))

const BudgetQuoteCar = ({
  car,
  quoteData,
  onNextStep = () => {},
  onBackStep = () => {},
  isMobile = false,
}) => {
  const classes = useStyle()
  const brandAndModel = `${car?.brand.name} ${car?.model.name}`
  const versionName = car?.version.name
  const year = car?.year
  const mileage = car?.mileage
  const condition = transformCondition(car?.condition)
  const estimatedPrice = quoteData?.carQuote?.quote?.prices.find(
    (e) => e.type === 'estimated'
  ).amount;
  const { budgetDispatch, budget } = useAppContext();
  const { editCar } = budget;

  const handleAddCarBudget = () => {
    if(editCar){
      quoteData.carQuote.id = budget.editCar.id;
      budgetDispatch({ type:UPDATE_USED_CAR, data:quoteData.carQuote });
      budgetDispatch({
        type: SET_EDIT_USED_CAR,
        data:
          null
        ,
      });
    }else {
      budgetDispatch({ type:SET_ADD_USED_CAR, data:quoteData.carQuote });
    }
    goToPresupuesto(budget.car, true);
  };

  return (
    <Grid className={classes.budgetQuoteContainer}>
      <Box className={classes.containerText}>
        <Box className={[classes.horizontalContainer, classes.budgetTitle]}>
          <Text className={classes.textTitle} semibolder large>
            {TITLE}
          </Text>
          <Text
            className={classes.link}
            bold
            small
            onClick={() => onBackStep()}
          >
            Editar
          </Text>
        </Box>
        <StepConnector className={classes.separator} />
        <Box className={[classes.horizontalContainerBrandModel, classes.generalMargin]}>
          <Text
            className={clsx(classes.text, classes.brandAndModel)}
            semibolder
            large
          >
            {brandAndModel}
          </Text>
          <Text className={classes.text} medium>
            {versionName}
          </Text>
        </Box>
        <Box>
          <Text className={classes.text} light medium>
            Año:
            <Text className={classes.blackText} bold>
              {year}
            </Text>
          </Text>
          <Text className={classes.text} light medium>
            Kilometraje:
            <Text className={classes.blackText} bold>
              {formaterNumber(mileage)}
            </Text>
          </Text>
          <Text className={classes.text} light medium>
            Estado:
            <Text className={classes.blackText} bold>
              {condition}
            </Text>
          </Text>
        </Box>
      </Box>
      <Box className={classes.infoBox}>
        <Box className={classes.horizontalContainer}>
          <Text semibold medium>
            {BODY_TEXT}
          </Text>
          <Text semibold medium>
            {formatPrice(estimatedPrice)}
          </Text>
        </Box>
      </Box>

      <Box className={clsx(classes.containerText, classes.quoteFooter)}>
        <Text light>{FOOTER_TEXT}</Text>
      </Box>
      <Box display="flex" className={classes.buttonContainer}>
        <Button
          primary
          className={classes.buttonAddToBudget}
          onClick={handleAddCarBudget}
        >
          {BUTTON_TEXT}
        </Button>
      </Box>
    </Grid>
  )
}

export default BudgetQuoteCar
