import React from 'react';
import ResponsiveStatusModal from '../../common/ResponsiveStatusModal.component';
import { makeStyles} from '@material-ui/core'

import {
  MESSAGE_LOADING,
  RESERVE_LEYEND_LOADING
} from '../../../containers/budget/constants';

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '3rem',
      paddingTop: '0rem',
    },
  }
}));

const BudgetLoadingModal = ({ setOpenModal, isMobile }) => {
  const classes = useStyles();
  return (
    <ResponsiveStatusModal
      loading={true}
      isMobile={isMobile}
      open={true}
      containerClassName={`${classes.containerLoading}`}
      loadingMessage={MESSAGE_LOADING}
      loadingLegend={RESERVE_LEYEND_LOADING}
      onClose={() => {setOpenModal(false);}}
    />
  );
};

export default BudgetLoadingModal;
