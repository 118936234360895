import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import QuoteFormContainer from '../../containers/quotation/QuoteForm.container'

const useStyle = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'left'
    },
    aditionalText:{
      marginTop:'0.5rem',
      color: theme.palette.grayScale.g600
    }
}))

const SaleCarForm = ({sellYourCarButton, onGetQuote, onFormEmpty, previousQuote, clearAllFields, addToBudget }) => {
  const classes = useStyle();

  const quoteFilters = {
    brand: previousQuote.brand.name,
    brandId: previousQuote.brand.id,
    model: previousQuote.model.name,
    modelId: previousQuote.model.id,
    year: previousQuote.year,
    version: previousQuote.version.id,
    kms: previousQuote.isKmsCalculated ? '' : previousQuote.mileage,
    estado: previousQuote?.condition?.toString(),
    tipo_cotizador: previousQuote.tipo_cotizador,
  }

    return (
    <Box className={classes.container}>
      <QuoteFormContainer
        onGetQuote={onGetQuote}
        horizontalForm={false}
        buttonText={sellYourCarButton}
        onFormEmpty={onFormEmpty}
        filters={quoteFilters}
        clearAllFields={clearAllFields}
        addToBudget={addToBudget}
      />
    </Box>
  );
};

export default SaleCarForm;