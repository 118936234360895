import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../common/text.component';
import { formaterNumber } from '../../utils/currency.utils';

const useStyles = makeStyles((theme) => ({
  sectionInfoUsedCarVersion: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  txtBrandModel: {
    color: '#5C5C5C',
    fontWeight: 600,
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight:'1.5rem'
    },
  },
  txtVersion: {
    color: '#5C5C5C',
    fontWeight: 400,
    textTransform: 'upperCase',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      fontSize: '0.875rem',
      lineHeight:'1.313rem'
    },
  },
  txtYearKmState: {
    color: '#717171',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      lineHeight:'1.25rem'
    },
  },
  divider: {
    width: '4px',
    height: '4px',
    background: '#717171',
    borderRadius: '50%',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  priceUsedCar: {
    color: '#262626',
    fontWeight: 500,
    whiteSpace:'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight:'1.5rem',
    },
  },
  sectionContainer:{
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
    },
  },
}));

const UsedCar = ({ usedCard }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Box className={classes.sectionInfoUsedCar}>
      <div className={classes.sectionInfoUsedCarVersion}>
        <div className={classes.sectionContainer}>
          <div>
            <Text className={classes.txtBrandModel}>{usedCard.car.marca}</Text>
            <Text className={classes.txtBrandModel}>{usedCard.car.modelo}</Text>
          </div>
          {isMobile && <br />}
          <div>
            <Text className={classes.txtVersion}>{usedCard.car.version}</Text>
          </div>
        </div>
        <div>
          <Text className={classes.priceUsedCar}>
            -$ {formaterNumber(usedCard.quote.prices[1].amount)}
          </Text>
        </div>
      </div>
      <div className={classes.txtYearKmState}>
        <Text>{usedCard.car.year}</Text>
        <div className={classes.divider}></div>
        <Text>{formaterNumber(usedCard.carInfo[0].value)} km</Text>
        <div className={classes.divider}></div>
        <Text>{usedCard.carInfo[1].value}</Text>
      </div>
    </Box>
  );
};

UsedCar.propTypes = {
  usedCar: PropTypes.node,
};

export default UsedCar;
