export const transformCondition = (condition) => {
  switch (condition) {
    case 1:
      return 'Regular'
    case 5:
      return 'Bueno'
    case 10:
      return 'Excelente'
  }
};
