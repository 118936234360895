import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Radio from '../common/radio.component';
import Text from '../common/text.component';
import { formaterNumber, formatFractionDigits } from '../../utils/currency.utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'strech',
    padding: '1rem',
    border: '1px solid #E8E8E8',
    borderRadius: '20px',
    width: '100%',
    color: '#262626',
    marginTop: '1rem',
  },
  radioButton: {
    margin: '0 0px 0 4px'
  },
  amount: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '27px',
  },
  tna: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '24px',
  },
  tradename: {
    fontSize: '12px',
    lineHeight: '20px',
    color: '#717171',
  },
  edit: {
    marginTop: '1rem',
    color: '#7D10FF',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '24px',
    cursor: 'pointer',
    '&:hover': {
      color: '#422370'
    }
  }
}));

const CardCreditLine = ({
  creditLine,
  condition,
  onItemClick = () => {},
  isItemSelected,
  showRadioButton,
  showEdit,
  onClickEdit
}) => {
  const classes = useStyles();

  return (
    <Box id="item-credit-line" className={classes.container} onClick={onItemClick}>
      {showRadioButton &&
        <Box display="flex" alignItems="center">
          <Radio
            checked={isItemSelected}
            size="small"
            data-test-id="select-radio"
            className={classes.radioButton} />
        </Box>}
      <Box display={'flex'} gridGap={'4px'} flexDirection='column' flexWrap='wrap'>
        <Text className={classes.amount}>
          {creditLine.bank} - ${formaterNumber(condition.capMax)} en {condition.quantity} meses
        </Text>
        <Text className={classes.tna}>
          Tasa {creditLine.tipoTasa} / TNA {formatFractionDigits(condition.tna)}% - CFT {formatFractionDigits(condition.cft)}%
        </Text>
        <Text className={classes.tradename}>{creditLine.nameComercial}</Text>
        {showEdit &&
          <Text onClick={onClickEdit} className={classes.edit}>Cambiar línea financiera</Text>
        }
      </Box>
    </Box>
  );
};

export default CardCreditLine;
