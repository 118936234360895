import React, { useEffect } from 'react';
import Layout from '../../components/layouts/layout.component';
import FinanceLineInfoContainer from './FinanceLineInfo.container';
import { useAppContext } from '../../context/AppContext';
import { useMediaQuery, useTheme } from '@material-ui/core';
import BackArrow from '../../components/header/BackArrow.component';
import { goToPresupuesto } from '../../api/navigation';
import { SET_BACK_BUTTON } from '../../context/budget/constants';

const PAGE_TITLE = 'Simula tu credito';
const META_DESCRIPTION = 'Simula tu credito';

const FinanceLinePageContainer = (props) => {
  const { location } = props;
  const pageContext = {
    ...props.pageContext,
  };

  const { budget, budgetDispatch } = useAppContext();
  const { car } = budget;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    budgetDispatch({
      type: SET_BACK_BUTTON,
      data: {
        onBackClick: handleBackClick,
      },
    });
  }, [])
  
  const handleBackClick = () => {
    goToPresupuesto(car);
  };

  return (
    <Layout
      title={PAGE_TITLE}
      description={META_DESCRIPTION}
      location={location}
      pageContext={pageContext}
      showMobileBackIcon
      onGoBack={handleBackClick}
    >
      {!isMobile && (
        <BackArrow entityName='budget'/>
      )}
      <FinanceLineInfoContainer routeMode={props.routeMode}/>
    </Layout>
  );
};

export default FinanceLinePageContainer;
