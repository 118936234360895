import React, { useCallback } from 'react';
import Layout from '../../components/layouts/layout.component';
import BackArrow from '../../components/header/BackArrow.component';
import BudgetCreditLines from '../../components/financing-line/BudgetCreditLines.component';
import { BUDGET } from '../../utils/constants';
import deleteCreditLine from '../../context/budget/actions/deleteCreditLine';
import { goToPresupuesto } from '../../api/navigation';
import { useAppContext } from '../../context/AppContext';
import { useMediaQuery, useTheme } from '@material-ui/core';

const PAGE_TITLE = 'Presupuesto - Carmuv';
const META_DESCRIPTION = '';
const filterOptions = [
  {
    label:'Mayor capital a financiar',
    value: BUDGET.CREDIT_LINE_SORT.MAX_CAPITAL_DESC
  },
  {
    label:'Menor tasa de interés',
    value: BUDGET.CREDIT_LINE_SORT.TNA_ASC
  },
];


const BudgetCreditLinesContainer = (props) => {
  const { location } = props;
  const pageContext = {
    ...props.pageContext,
  };
  const { budget, budgetDispatch } = useAppContext();
  const { car } = budget;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBackClick = useCallback(() => {
    if(props.routeMode === BUDGET.CREDIT_LINE_CREATE_MODE) {
      deleteCreditLine(budgetDispatch);
    }

    goToPresupuesto(car);
  }, [car,budgetDispatch, props.routeMode]);

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader={false}
      description={META_DESCRIPTION}
      showMobileBackIcon
      onGoBack={handleBackClick}
    >
      {!isMobile && (
        <BackArrow entityName='budget'/>
      )}
      <BudgetCreditLines
        filterOptions={filterOptions}
        routeMode={props.routeMode}
      />
    </Layout>
  );
};

export default BudgetCreditLinesContainer;
