import React from 'react'
import Text from '../common/text.component'
import { Box, makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import clsx from 'clsx'
import Button from '../common/button.component'
import { goToHome } from '../../api/navigation'
import { default as SuccessIcon } from '../../assets/icons/success_icon.svg';

const GO_TO_HOME_BUTTON_HTML_ID = 'cotiza-btn-final-ir a home'
const useStyles = makeStyles((theme) => ({
  SaleCarSuccessContainer: {
    [theme.breakpoints.down('sm')]: {
      width: 'initial',
    },
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
    display: 'flex',
    flexDirection: 'column',
    margin: '1.25rem auto 2.5rem auto',
    textAlign: 'center',
  },

  SaleCarSuccessItemText: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginLeft: '1.25rem',
    marginBottom: '1.25rem',
  },

  fixedStepper: {
    width: '1.688rem',
    height: '1.688rem',
    borderRadius: '50%',
    outline: `2px dashed ${theme.palette.grayScale.g300}`,
  },

  currentStep: {
    outline: 'none',
    backgroundColor: theme.palette.success.main,
  },

  verticalLine: {
    width: '2px',
    height: '2.5rem',
    margin: '1rem auto',
    backgroundColor: theme.palette.grayScale.g300,
  },

  verticalLineSuccess: {
    backgroundColor: theme.palette.success.main,
  },

  icon: {
    width: '100%',
    padding: '2px',
  },

  homeButton: {
    margin: 'auto',
  },

  title: {
    padding: '0 1.5rem'
  },

  successIcon: {
    marginBottom: '1rem',
    textAlign: 'center',
    marginTop: '3rem',
  },
}));

const StepIcon = ({ complete, current }) => {
  const classes = useStyles()
  if (complete) {
    return (
      <Box className={clsx(classes.fixedStepper, classes.currentStep)}>
        <CheckIcon className={classes.icon} htmlColor="white" />
      </Box>
    )
  }
  if (current) {
    return <Box className={clsx(classes.fixedStepper, classes.currentStep)} />
  }
  return <Box className={classes.fixedStepper} />
}

const VerticalLine = ({ complete, lastStep }) => {
  const classes = useStyles()
  if (lastStep) {
    return null
  }
  if (complete) {
    return (
      <Box
        className={clsx(classes.verticalLine, classes.verticalLineSuccess)}
      />
    )
  }
  return <Box className={classes.verticalLine} />
}

const FixedStep = (props) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <StepIcon {...props} />
      <VerticalLine {...props} />
    </Box>
  )
}

const SaleCarSuccess = () => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" margin="0 auto" padding="0 0 3rem">
      <Box
        className={classes.successIcon}
      >
        <SuccessIcon />
      </Box>
      <Text className={classes.title} center xLarge bold>¡Listo!</Text>
      <Box className={classes.SaleCarSuccessContainer}>
        <Text medium normal g600>
          Encontrá el resumen en tu bandeja de entrada, spam o correo no deseado.
        </Text>
        <Text medium normal g600>
          En las próximas horas te llamamos para contarte cómo seguir.
        </Text>
      </Box>
      <Button
        id={GO_TO_HOME_BUTTON_HTML_ID}
        onClick={goToHome} className={classes.homeButton} primary>
        Ir al home
      </Button>
    </Box>
  )
}

export default SaleCarSuccess
