import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import SuccessIcon from '../../assets/icons/success_icon.svg';
import Text from '../common/text.component';
import VehicleInfo from '../budget/VehicleInfo.component';
import Link from '../common/Link.component';
import { GET_BUDGET } from '../../queries/getBudget';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../common/Loading.component';
import { GET_BUDGET_LEGALS } from '../../queries/getLegalsBudget';
import { useLocation } from '@reach/router';
import { goToCatalog, goToCuenta } from '../../api/navigation';

const TITLE = '¡Ya reservamos tu auto, ';
const SUBTITLE = '¿Qué sigue después?';
const DESCRIPTION = 'Comunicarnos con vos en las próximas horas para contarte cómo seguir.' ;
const ACTION_DESCRIPTION = 'También podés seguir tu reserva desde tu cuenta';
const ACTION_LABEL = 'Ver mi reserva';

const TITLE_WITH_OUT_PAY='¡Listo! Estás mas cerca de tu auto nuevo'
const ACTION_LABEL_WITH_OUT_PAY = 'Ver más autos'
const ACTION_DESCRIPTION_WITH_OUT_PAY = 'Mientras, ¿qué te parece conocer toda nuestra oferta en autos?'


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      textAlign: 'center',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
  },
  title: {
    marginTop: '1.25rem',
    marginBottom: '1rem',
    fontSize: '26px',
    fontWeight: '700',
    lineHeight: '39px',
  },
  descriptiion: {
    marginTop: '1rem',
    marginBottom: '2.5rem',
  },
  infoContainer: {
    width: 'min(34.75rem, 100%)',
    paddingLeft: '1.125rem',
    paddingRight: '1.125rem',
    minHeight: '8rem',
    flexDirection: 'row',
    border: '1px solid',
    borderColor: theme.palette.grayScale.g300,
    borderRadius: '0.625rem',
    alignItems: 'center',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0',
      paddingRight: '0',
    }
  },
  photo: {
    minHeight: '7rem',
    maxWidth: '9.375rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '150px',
    },
  },
  media: {
    minWidth: '9.375rem',
    [theme.breakpoints.down('md')]: {
      width: '150px',
      padding: '0',
      overflow: 'hidden',
    }
  },
  infoDetail: {
    marginTop: '0.75rem'
  },
  brandContainer: {
    marginBottom: '0.5rem'
  },
  yearFuelColor: {
    marginTop: '0.5rem'
  },
  forgotLink: {
    marginLeft:'0.625rem',
    marginTop: theme.spacing(1),
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '24px'
  },
  legalText: {
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '3rem',
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
  descriptiion2: {
    marginTop: '1rem',
    marginBottom: '0.7rem',
    paddingTop: '1rem',
  },
}));

function CompletedReservation() {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const budgetId = searchParams.get('budgetId');
  const withOutPay = searchParams.get('sinPago');
  const [carData, setCarData] = useState(null);

  const { data, refetch } = useQuery(GET_BUDGET, {
    variables: {
      id: budgetId
    },
    onCompleted: (success) => {
      success && refetch();
    }
  });

  React.useEffect(() => {
    data && setCarData(data?.getBudget || null);
  }, [data]);

  const { data: budgetLegalsResponse } = useQuery(GET_BUDGET_LEGALS, {
    variables: {
      options: {
        hasTakingCar: carData?.hasUsedCar,
        hasFinancing: carData?.hasCreditLine,
      },
    },
  });

  return carData === null ? <Loading size={20} /> :  (
    <><Box className={classes.container}>
      <SuccessIcon />
      {withOutPay ? <Text className={classes.title}>{`${TITLE_WITH_OUT_PAY}`}</Text> : 
      <Text className={classes.title}>{`${TITLE} ${carData.client_first_name}!`}</Text>}
      
      <Text bold large>{SUBTITLE}</Text>
      <Text className={classes.descriptiion} g600>{DESCRIPTION}</Text>
      <VehicleInfo
        classes={classes}
        mobile
        car={{
          images: [carData?.image_car],
          brand: { name: carData?.catalog_marca ? carData?.catalog_marca : '' },
          model: { name: carData?.catalog_model ? carData?.catalog_model : '' },
          version: { name: carData?.catalog_version },
          is0km: true,
          year: carData?.catalog_anio || '',
          fuel: carData?.fuel,
        }}
        selectedStock={{ observation: carData?.observation, color: { name: carData?.color.name } }} />
        {withOutPay ? <Text className={classes.descriptiion2} g600>{ACTION_DESCRIPTION_WITH_OUT_PAY}</Text> : 
      <Text className={classes.descriptiion2} g600>{ACTION_DESCRIPTION}</Text>}
      {withOutPay ? <Link inverted className={classes.forgotLink} onClick={() => goToCatalog()}>
      {ACTION_LABEL_WITH_OUT_PAY}
    </Link> : 
      <Link inverted className={classes.forgotLink} onClick={() => goToCuenta(2)}>
      {ACTION_LABEL}
    </Link>}
    </Box><Box className={classes.legalText}>
      <Text g600>
        {budgetLegalsResponse.getBudgetLegals.map((text) => (
          <div key={text}>{text}</div>
        ))}</Text>
    </Box></>

  );
}

export default CompletedReservation;
