import React from 'react';
import { Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useAppContext } from '../../context/AppContext';
import Link from '../common/Link.component';
import Text from '../common/text.component';
import { makeStyles } from '@material-ui/core/styles';


const DEFAULT_LABEL = 'Volver';

/**
 * @typedef {Object} BackArrowProps
 * @property {String} entityName nombre de la entidad a consumir
 */

const useStyles = makeStyles((theme) => ({
  containerBackButton: {
    marginTop: '4rem',
    width: '100%',
    paddingLeft: theme.spacing(9),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      marginTop:'4.825rem',
      marginBottom: '0.825rem',
    },
  },
  backButton: {
    color: '#7D10FF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight:'600',
    '&:hover': {
      color: '#422370'
    }
  }
}));

/**
 * Renderiza el boton Volver con
 * el contenido de backLabel y onBackClick
 * del context de datos de la app
 * @param {BackArrowProps} props
 */
export default function BackArrow(props) {
  const { entityName } = props;

  const classes = useStyles();

  const context = useAppContext();
  const data = context[entityName];

  return (
    <Box className={classes.containerBackButton}>
      <Link className={classes.backButton} onClick={data.onBackClick}>
        <Box display="flex" alignItems="center">
          <ArrowBackIosIcon style={{ fontSize: 12 }} />
          <Text>{data.backLabel || DEFAULT_LABEL}</Text>
        </Box>
      </Link>
    </Box>
  );
}
