import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  inputText: {
    width: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
    marginBottom: '1.2rem',
    '& input': {
      paddingTop: '0.938rem',
      paddingBottom: '0.938rem',
      paddingLeft: '1.2rem',
      paddingRight: '1.5rem',
      '&.Mui-disabled': {
        background: '#F7F7F7',
        color: '#717171',
        border: 'none',
        borderRadius: '25px',
      },
    },
    '& label': {
      fontWeight: 500,
      color: `${theme.palette.grayScale.g600} !important`,
      transform: 'translate(1.6rem, 1rem) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(1.6rem, -1.5rem) !important',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#5C5C5C',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '1.2rem !important',
      fontWeight: '400 !important',
      fontSize: '14px !important',
      lineHeight: '21px !important',
      color: '#717171 !important',
      height: '1.5rem',
      [theme.breakpoints.down('md')]:{
        maxWidth:'350px',
      },
      '&.Mui-error': {
        color: '#C53814 !important',
      }
    },
    '&.Mui-error': {
      color: '#C53814 !important',
    }
  },
  formTitle: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    color: '#262626',
    marginLeft: '0.5rem',
    marginBottom: '1.5rem',
  },
  saleCarTitleStep:{
    '& #title':{
      fontWeight: 700,
      fontSize: '1.625rem',
    },
    '& #subtitle': {
      marginBottom: '1rem'
    },
  },
  dropdownInput: {
    '& .MuiAutocomplete-input': {
      height: '1.8125rem',
      paddingLeft: '1.5rem !important',
      marginLeft: '-0.5rem',
    },
  },
  inputMoney: {
    '& input': {
      paddingLeft: '.1rem',
    },
  },
  optionStyles: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '100%',
    padding: '10px 20px',
    margin: 0,
    color: theme.palette.grayScale.g500,
  },
  paperStyles: {
    '& .MuiAutocomplete-paper': {
      '@media (max-width: 796px) and (min-width: 480px)': {
        marginRight: 1,
      },
      '@supports ( -moz-appearance:none )': {
        marginRight: 1,
      },
      borderRadius: theme.borderRadius.large,
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.25)',
      border: `1px solid ${theme.palette.grayScale.g200}`,
    },
  },
}));
