import { SET_ORDER_CREDITS_LINES } from '../constants';

export default function (order, budgetDispatch){

  budgetDispatch({
    type: SET_ORDER_CREDITS_LINES,
    data: {
      orderCreditsLines: order
    }
  });
}
