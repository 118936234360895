import React from 'react'
import PropTypes from 'prop-types'
import {Box, makeStyles} from '@material-ui/core'
import Text from './text.component'

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
  },
  title:{
    color: theme.palette.grayScale.g500,
    [theme.breakpoints.up('md')]: {
      lineHeight: '2.813rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'initial',
      margin: 'auto',
    },
  },
  subtitle:{
    textAlign:'initial',
    color:theme.palette.grayScale.g600,
  }
}))
const SaleCarTitleStep = ({ title, subTitle, className}) => {
  const classes = useStyle();

  return (
    <Box className={[classes.container, className]}>
      <Text className={classes.title} xLarge bold id='title'>{title}</Text>
      {subTitle && <Text className={classes.subtitle} medium id='subtitle'>{subTitle}</Text>}
    </Box>
  );
};

SaleCarTitleStep.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
}

export default SaleCarTitleStep ;
