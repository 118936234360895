import React from 'react'
import PropTypes from 'prop-types'
import LoadingModal from '../common/LoadingModal.component'

const SaleLoadingModal = ({ subtitle, title, open, modalSubtitleStyle }) => {
  return (
    <LoadingModal
      open={open}
      disableBackdropClick={true}
      message={title}
      leyend={subtitle}
      updating={true}
      customVisibility={true}
      modalSubtitleStyle={modalSubtitleStyle}
    />
  )
}

SaleLoadingModal.propTypes = {
  title: PropTypes.string.isRequired || PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
}

export default SaleLoadingModal
