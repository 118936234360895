import { RESET_BUDGET_STATE } from '../constants';

/**
 * Re-inicializa el estado con su valor inicial
 */
export default function (budgetDispatch){
  budgetDispatch({
    type: RESET_BUDGET_STATE
  });
}
