import { useTheme, useMediaQuery } from '@material-ui/core'
import React from 'react'
import Layout from '../../components/layouts/layout.component'
import BudgetQuoteContainer from './BudgetQuote.container'

const PAGE_TITLE = 'Entrega de usado'
const META_DESCRIPTION = 'Entrega de usado'

const BudgetUsedCarSurrenderContainer = ({ location, pageContext }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Layout
      title={PAGE_TITLE}
      description={META_DESCRIPTION}
      location={location}
      pageContext={pageContext}
      showMobileBackIcon
    >
      <BudgetQuoteContainer isMobile={isMobile}/>
    </Layout>
  )
}

export default BudgetUsedCarSurrenderContainer
