import React, { useState } from 'react';
import { makeStyles, Box, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '../../components/common/button.component';
import TermsAndConditions from '../../components/common/TermsAndConditions.component';
import {
  roundBase1000,
  validateEmail,
  validateIsRequired,
  validateMinLength,
  validateMinMaxNumber,
} from '../../utils/validators.utils';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import { GET_ACTIVE_STOCKS } from '../../queries/getActiveStocks';
import updateStockFields from '../../context/budget/actions/updateStockFields';
import { ERROR_BUDGET, ERROR_RESERVATION, LOADING_BUDGET, LOADING_RESERVATION } from './constants';
import ErrorMessage from '../../components/common/ErrorMessage.component';
import { useAppContext } from '../../context/AppContext';
import setFormHasData from '../../context/budget/actions/setFormHasData';
import { generateBudgetBody } from './utils';
import BudgetClientFormComponent from './BudgetClientForm.component';
import BudgetControllerModal from '../../components/budget/modals/BudgetControllerModal';
import _ from 'lodash';
import { formaterNumber } from '../../utils/currency.utils';
import { ID_ARG } from '../../utils/constants';
import { SEND_PAYMENT } from '../../mutations/payment';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      minHeight: '36.375rem',
      marginTop: '2rem',
      alignItems: 'flex-start',
    },
  },
  buttonSubmitContainer: {
    float: 'right',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  buttonSubmit: {
    height: '48px',
    width: '162px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1.5rem',
      marginTop: '2.5rem',
    },
  },
  saleCarTitleStep: {
    '& #title': {
      fontSize: '1.625rem',
    },
    '& #subtitle': {
      color: theme.palette.grayScale.g600,
    },
  },
}));

const initialStateFields = (client) => ({
  name: client?.name ?? '',
  lastName: client?.lastName ?? '',
  email: client?.email ?? '',
  phone: client?.phone ?? '',
});

const TEXT_MASK = new Array(15).fill(/^[A-Za-z\s]*$/);

const BudgetClientForm = ({
  clientData = {} , onStartPaymentClick = async () => {}, disableClientFormFields = false, isResaleAndWithoutPay
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [errorMessages, setErrorMessages] = useState(null);
  const [fields, setFields] = useState(initialStateFields(clientData));
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openModal, setOpenModal] = useState(false );
  const [reservationMade, setReservationMade] = useState(false);
  const { budgetDispatch, user, budget } = useAppContext();
  const { profile: { role_name: roleName } = {} } = user;
  const [modalState, setModalState] = useState(0);
  // agregado porque la PO pidió validar distinto el monto.
  const [ validateAmount, setValidateAmount ] = useState(false);

  const onRequestError = () => {
    setModalState(ERROR_BUDGET);
    setReservationMade(false);
  };

  // Se inicializa con skip para que solo se haga la request con refetch
  const { refetch: getActiveStocks } = useQuery(GET_ACTIVE_STOCKS, { skip: true });

  const [payment ] = useMutation(SEND_PAYMENT,{
    onCompleted: (data)=> {
      navigate(data?.payment?.url);
    },
    onError: () => {
      onRequestError();
    }
  });

  const sendPayment = (budgetId) => {
    payment({
      variables: { dataPayment: {
        clientData:{
          firstName: fields?.name,
          lastName:fields?.lastName,
        	email: fields?.email,
          cp:fields.cp,
          phone:fields?.phone,
          cuit: fields.cuit.replaceAll('-',''),
        },
        budgetId,
        monto:parseInt(fields?.amount.replaceAll('.','')),
        is0km:budget?.car?.is0km,
        brand:budget?.car?.brand?.name,
        model:budget?.car?.model?.name,
        version:budget?.car?.version?.name,
      } },
    });
  };


  const { car, finalPrice } = budget;

  const validateFields = (fields) => {
    const isValidEmail = fields.email
      ? validateEmail(
        fields.email,
        <ErrorMessage>El e-mail no es válido. ¿Lo intentas de nuevo?</ErrorMessage>
      )
      : validateIsRequired(
        fields.email,
        <ErrorMessage>Necesitamos tu e-mail para poder contactarte.</ErrorMessage>
      );
    const isValidName = validateIsRequired(
      fields.name,
      <ErrorMessage>Nos gustaría conocer tu nombre.</ErrorMessage>
    );
    const isValidLastName = validateIsRequired(
      fields.lastName,
      <ErrorMessage>Nos gustaría conocer tu apellido.</ErrorMessage>
    );
    const isValidPhone = fields.phone
      ? validateMinLength(
        fields.phone,
        10,
        (minLength) => <ErrorMessage>Tiene que tener {minLength} caracteres.</ErrorMessage>
      )
      : validateIsRequired(
        fields.phone,
        <ErrorMessage>Necesitamos tu teléfono para poder comunicarnos.</ErrorMessage>
      );
    const isValidCuit = fields.cuit
      ? validateMinLength(
        fields.cuit,
        13,
        () => ('Tiene que tener 11 dígitos.')
      )
      : validateIsRequired(
        fields.cuit,
        'Necesitamos tu CUIT/CUIL para reservar tu auto.'
      );
    const isValidAddress = fields.address
      ? validateMinLength(
        fields.address,
        1,
        () => ('Contanos tu dirección.')
      )
      : validateIsRequired(
        fields.address,
        'Contanos tu dirección.'
      );
    const isValidProvince = fields.province
      ? validateMinLength(
        fields.province,
        1,
        () => 'Contanos en qué provincia residís.'
      )
      : validateIsRequired(
        fields.province,
        'Contanos en qué provincia residís.'
      );
    const isValidCity = fields.city
      ? validateMinLength(
        fields.city,
        1,
        () => ('Contanos en qué localidad.')
      )
      : validateIsRequired(
        fields.city,
        'Contanos en qué localidad.',
      );
    const isValidCp = fields.cp
      ? validateMinLength(
        fields.cp,
        4,
        () => ('Debe contener 4 o 5 dígitos.')
      )
      : validateIsRequired(
        fields.cp,
        'Contanos el código postal.'
      );

    const valid =
      isValidEmail.valid &&
      isValidName.valid &&
      isValidLastName.valid &&
      isValidPhone.valid &&
      isValidCuit.valid &&
      isValidAddress.valid &&
      isValidProvince.valid &&
      isValidCity.valid &&
      isValidCp.valid;

    const errors = {
      name: isValidName,
      lastName: isValidLastName,
      email: isValidEmail,
      phone: isValidPhone,
      cuit: isValidCuit,
      address: isValidAddress,
      province: isValidProvince,
      city: isValidCity,
      cp: isValidCp,
    };
    return { valid, errors };
  };

  const validateForm = (newFields) => {
    const { valid, errors } = validateFields(newFields);
    setErrorMessages(errors);
    return valid;
  };

  /**
   * Guarda el presupuesto en BD y envia el mail al usuario que realizó la reserva
   */
  const sendBudget = async () => {
    // Se formatean los datos a enviar
    const budgetData = generateBudgetBody(budget, fields, roleName, 'Reserva');
    const carName = `${car.brand.name } ${car.model.name} ${car.version.name}`;
    const emailData = {
      clientData: {
        ...fields,
        firstName : fields?.name,
        cuit: !isResaleAndWithoutPay ? fields.cuit.replaceAll('-','') : '',
        country: ID_ARG
      },
      templateData: {
        carName,
        price: finalPrice
      }
    };
    delete emailData?.clientData?.name;

    // Se guarda en BD
    try {
      const budgetResponse = await onStartPaymentClick(emailData, budgetData);
      return { success:budgetResponse?.data?.sendBudget?.success, budgetId:budgetResponse?.data?.sendBudget?.budgetId };
    } catch (error) {
      onRequestError();
      return { success:false };
    }
  };

  /**
   * Verifica que el stock seleccionado al iniciar el presupuesto siga disponible
   */
  const checkStockAvailability = async () => {
    try {
      const selectedStock = car.stock.find(({ id }) => id === car.selectedStock);

      const response = await getActiveStocks({
        stockId: car.selectedStock,
        catalogId: car.id,
        colorId: selectedStock.color.id,
        stockComment: selectedStock.observation
      });

      // Si no quedan stocks en el grupo seleccionado
      // se muestra el modal de error
      if(!response?.data?.getStockGroupItem) {
        setModalState(ERROR_RESERVATION);
        return false;
      }

      updateStockFields(response.data.getStockGroupItem, budgetDispatch);
      return true;
    } catch (error) {
      onRequestError();
      return false;
    }
  };

  const handleOnSubmit = async () => {

    if (!isResaleAndWithoutPay) {
      const isValid = validateForm(fields) && validateAmount?.valid;
  
      if (isValid) {
        // Se abre el modal en estado de carga
        setModalState(LOADING_BUDGET);
        setOpenModal(true);
  
        // Se genera el prespuesto
        const { success, budgetId } = await sendBudget();
  
        if(!success) return;
  
        // Se deshabilitan los campos
        setReservationMade(true);
  
        // Si se pudo crear el presupuesto
        // Se checkea el stock
        const stockValidationSuccess = await checkStockAvailability();
  
        if(!stockValidationSuccess) return;
  
        // Si queda un stock disponible se procede al pago
        setModalState(LOADING_RESERVATION);
        sendPayment(budgetId);
      }

    }else {
      // Se abre el modal en estado de carga
      setModalState(LOADING_BUDGET);
      setOpenModal(true);

      // Se genera el prespuesto
      const { success, budgetId } = await sendBudget();

      if(!success) return;

      const withOutPay = true

      navigate(`/presupuesto/reserva-completada/?sinPago=${withOutPay}&budgetId=${budgetId}`);
    }
  };

  const checkFormIsEmpty = (newFields) => {
    return !Boolean(newFields.email)
    && !Boolean(newFields.lastName) && !Boolean(newFields.name)
    && !Boolean(newFields.phone);
  };

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields, [key]: value };
    const isEmpty = checkFormIsEmpty(newFields);
    setFormHasData(budgetDispatch, !isEmpty);
    setFields(newFields);
    setErrorMessages(_.omit(errorMessages, key));
    if (key === 'amount') {
      setValidateAmount(validateMinMaxNumber(
        parseInt(value.toString().replaceAll('.','')),
        roundBase1000(finalPrice), finalPrice,
        `Tiene que ser mayor o igual a $ ${formaterNumber(roundBase1000(finalPrice))}`,
        `Tiene que ser menor o igual a $ ${formaterNumber(finalPrice)}`,
      )
      );
    }
  };

  React.useEffect(() => {
    handleTextFieldChange('amount', formaterNumber(roundBase1000(finalPrice)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[finalPrice]);


  return (
    <Box className={classes.container}>
      <BudgetClientFormComponent
        validateForm={validateForm}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
        onTextFieldChange={handleTextFieldChange}
        fields={fields}
        isColumn={true}
        lablePhone="Teléfono"
        nameMask={TEXT_MASK}
        disableFields={reservationMade || disableClientFormFields}
        phoneMaxLength={10}
        minPrice={roundBase1000(finalPrice)}
        maxPrice={finalPrice}
        validateAmount={validateAmount}
        isResaleAndWithoutPay={isResaleAndWithoutPay}
      />
      <TermsAndConditions
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
        disableCheck={reservationMade}
      />
      <Box className={classes.buttonSubmitContainer} >
        <Button
          disabled={!termsAccepted || reservationMade}
          className={classes.buttonSubmit}
          onClick={handleOnSubmit}
          primary>
          Solicitar reserva
        </Button>
      </Box>
      <BudgetControllerModal
        state={modalState}
        isMobile={isMobile}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Box>
  );
};

export default BudgetClientForm;
