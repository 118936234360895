import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import Link from '../common/Link.component'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Text from '../common/text.component'
import { useAppContext } from '../../context/AppContext'
import { navigationRoutes } from '../../api/navigationRoutes.enum'
import { navigate } from 'gatsby'
import deleteCreditLine from '../../context/budget/actions/deleteCreditLine';
import { BUDGET } from '../../utils/constants'

const useStyle = makeStyles((theme) => ({
  boxArrowBack: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  arrowBack: {
    marginRight: '0.375rem',
  },
  arrowBackText: {
    lineHeight: '1.5rem',
  },
  link: {
    marginTop: '3.9rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4.5rem',
      marginLeft: '1.725rem',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '4rem',
      marginLeft: '4.7rem',
    },
    '& :hover': {
      color: theme.palette.primary.dark,
    },
  },
}))

const BudgetBackButton = ({ text, routeMode, marginLeft }) => {
  const classes = useStyle()
  const { budget, budgetDispatch } = useAppContext()
  const car = budget.car
  const stockId = car?.stock?.find(stock => stock.id === car.selectedStock)?.id
  const goToBudget = () => {
    if(routeMode && routeMode !== BUDGET.CREDIT_LINE_EDIT_MODE ) {
      deleteCreditLine(budgetDispatch);
    }
    navigate(
      `${
        navigationRoutes.PRESUPUESTO.ROOT
      }${car.brand.name.toLowerCase()}/${car.model.name.toLowerCase()}/${car.version.name.toLowerCase()}/?productId=${
        car.id
      }&stockId=${stockId}`
    )
  }
  return (
    <Box className={classes.link} style={marginLeft ? { marginLeft } : null}>
      <Link inverted onClick={goToBudget}>
        <Box className={classes.boxArrowBack}>
          <ArrowBackIosIcon className={classes.arrowBack} />
          <Text small semibolder className={classes.arrowBackText}>
            {text}
          </Text>
        </Box>
      </Link>
    </Box>
  )
}

BudgetBackButton.propTypes = {
  handleGoBack: PropTypes.func,
  text: PropTypes.string,
}

export default BudgetBackButton
