import gql from 'graphql-tag';

export const GET_DELIVERY_COSTS = gql`
query GetDeliveryCosts(
    $is0km: Boolean,
    $isFromClient: Boolean,
    $country: String,
    $installments: Int,
    $installmentsValue: Int,
    $listPrice: BigInt,
    $totalPrice: BigInt,
    $bonus: Int,
    $clientProvince: String,
    $isPrenda: Boolean,
  ) {
    getDeliveryCosts(budgetInfo: {
        is0km: $is0km,
        isFromClient: $isFromClient,
        country: $country,
        installments: $installments,
        installmentsValue: $installmentsValue,
        listPrice: $listPrice,
        totalPrice: $totalPrice,
        bonus: $bonus,
        clientProvince: $clientProvince,
        isPrenda: $isPrenda
    }
        ) {
            costs
    }
}
`;
