import gql from 'graphql-tag';

export const GET_ACTIVE_STOCKS = gql`
  query GetStockGroupItem($stockId: String, $catalogId: UUID, $colorId: UUID, $stockComment: String) {
    getStockGroupItem(stockId: $stockId, catalogId: $catalogId, colorId: $colorId, stockComment: $stockComment) {
        id
        observation
        originCountry
        estado
        vin
    }
  }
`;
