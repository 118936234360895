import { SET_CREDIT_LINE } from '../constants';

export default function (selectedLine, selectedCondition, budgetDispatch){

  budgetDispatch({
    type: SET_CREDIT_LINE,
    data: {
      ...selectedLine,
      condition: selectedCondition
    }
  });
}
