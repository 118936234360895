import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { formaterNumber, formatPrice } from '../../utils/currency.utils'
import { capitalizeText } from '../../utils/typography.utils'

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grayScale.g250,
    borderRadius: '0.625rem',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
      marginLeft: '.6rem',
      marginRight: '.6rem',
    },
    [theme.breakpoints.up('md')]: {
      padding: '2rem',
    },
    '& .mb-16': {
      marginBottom: '1rem',
    },
    '& .mb-13': {
      marginBottom: '0.8125rem',
    },
    '& .mb-12': {
      marginBottom: '0.75rem',
    },
    '& .mb-4': {
      marginBottom: '0.25rem',
    },
    '& .mt-12': {
      marginTop: '0.75rem',
    },
    '& .mr-10': {
      marginRight: '0.625rem',
    },
    '& .span-flex-between': {
      display: 'flex',
      justifyContent: 'space-between',
      '&.border-top': {
        marginTop: '2rem',
        paddingTop: '1.9063rem',
        borderTop: `0.0625rem solid ${theme.palette.grayScale.g700}`,
      },
    },
    '& .flex-column': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .subtitle-3': {
      fontWeight: '600',
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      color: theme.palette.grayScale.g500,
    },
    '& .subtitle-grey': {
      fontWeight: '500',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      '&.color-g500': {
        color: theme.palette.grayScale.g500,
      },
      '&.color-g600': {
        color: theme.palette.grayScale.g600,
      },
    },
    '& .subtitle-5': {
      fontWeight: '600',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      '&.color-g600': {
        color: theme.palette.grayScale.g600,
      },
      '&.color-main2': {
        marginLeft: '0.5rem',
        color: theme.palette.primary.main2,
      },
    },
    '& .mobile-expand': {
      color: theme.palette.primary.main2,
      display: 'flex',
      justifyContent: 'flex-end',
      '&.expand': {
        fontSize: '1.375rem',
      },
    },
    '& .version': {
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: theme.palette.grayScale.g600,
    },
    '& .body-3': {
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1.3125rem',
      color: theme.palette.grayScale.g400,
    },
    '& .headline-5': {
      fontWeight: '700',
      fontSize: '1.375rem',
      lineHeight: '2.0625rem',
      color: theme.palette.grayScale.g500,
    },
    '& .li': {
      margin: '0 0.5rem',
      color: theme.palette.grayScale.g400,
    },
    '& #span-detail': {
      marginTop: '0.75rem',
      marginBottom: '1rem',
      cursor: 'pointer',
    },
    '& .nowrap': {
      whiteSpace: 'nowrap',
    },
    '& .used-car-version': {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  cancelAmountText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#262626',
  },
  cancelAmount: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHight: '33px',
    color: '#262626',
  }
}))

const textDetailButtonDesktop = {
  true: 'Ocultar detalles',
  false: 'Mostrar detalle',
}
const textDetailButtonMobile = { true: 'Ocultar resumen', false: 'Ver resumen' }

const NEW_CAR_COSTS_NAME = 'Gastos de patentamiento';
const USER_CAR_COSTS_NAME = 'Gastos de transferencia';

const BudgetReservationData = ({ budgetReservationData, isMobile }) => {
  const {
    usedCars,
    deliveryCosts,
    usedCarsValue,
    financedAmount,
    financialCosts,
    finalPrice,
    vehiclePrice,
    bank,
    condition,
    nameComercial,
    is0km
  } = budgetReservationData
  const classes = useStyle()
  const [showDetail, setShowDetail] = useState(true)
  const [showMobileDetail, setShowMobileDetail] = useState(false)
  const showDetailButton = () => setShowDetail(!showDetail)
  const showMobileDetailButton = () => setShowMobileDetail(!showMobileDetail)
  const costsName = is0km ? NEW_CAR_COSTS_NAME : USER_CAR_COSTS_NAME;
  const detailArrow = showMobileDetail ? (
    <ExpandLess className={classes.expand} />
  ) : (
    <ExpandMore className={classes.expand} />
  )

  return (
    <Box className={classes.container}>
      {isMobile && (
        <span id="span-detail" className="mobile-expand">
          <a
            className="subtitle-5 color-main2"
            onClick={() => showMobileDetailButton()}
          >
            {textDetailButtonMobile[showMobileDetail]}
          </a>
          {detailArrow}
        </span>
      )}
      {(!isMobile || showMobileDetail) && (
        <>
          <span className="span-flex-between subtitle-3 mb-16">
            <label>Precio</label>
            <label className="nowrap">{formatPrice(vehiclePrice)}</label>
          </span>
          <span className="span-flex-between subtitle-grey color-g600 mb-16">
            <label className="mr-10">{costsName}</label>
            <label className="nowrap">{formatPrice(deliveryCosts)}</label>
          </span>
          {usedCars.length > 0 && (
            <span className="span-flex-between">
              <label className="subtitle-3 mr-10">
                Usado como parte de pago
              </label>
              <label className="subtitle-grey color-g500 nowrap">
                {formatPrice(usedCarsValue, '-$')}
              </label>
            </span>
          )}
          {showDetail && (
            <>
              {usedCars.map((usedCar) => {
                const { car, carInfo } = usedCar
                const marca = car.marca || ''
                const modelo = car.modelo || ''
                const version = car.version
                const year = car.year
                const kilometraje = carInfo.find(
                  (e) => e.label === 'Kilometraje'
                ).value
                const estado = carInfo.find((e) => e.label === 'Estado').value
                return (
                  <span className="flex-column mt-12">
                    <span className="used-car-version">
                      <label className="subtitle-5 color-g600">
                        {capitalizeText(marca)} {capitalizeText(modelo)}{' '}
                      </label>
                      <label className="version">{version}</label>
                    </span>
                    <span>
                      <label className="body-3">{year}</label>
                      <label className="li">•</label>
                      <label className="body-3">
                        {formaterNumber(kilometraje)} Km
                      </label>
                      <label className="li">•</label>
                      <label className="body-3">{estado}</label>
                    </span>
                  </span>
                )
              })}
            </>
          )}
          {!isMobile && usedCars.length > 0 && (
            <span id="span-detail">
              <a
                className="subtitle-5 color-main2"
                onClick={() => showDetailButton()}
              >
                {textDetailButtonDesktop[showDetail]}
              </a>
            </span>
          )}
          {financedAmount > 0 && (
            <>
              <span className="span-flex-between mb-12 mt-12">
                <label className="subtitle-3 mr-10">Monto financiado</label>
                <label className="subtitle-grey color-g500 nowrap">
                  {formatPrice(financedAmount, '-$')}
                </label>
              </span>
              <span className="span-flex-between mb-4">
                <label className="subtitle-5 color-g600">{nameComercial}</label>
              </span>
              <span className="mb-4">
                {bank && (
                  <>
                    <label className="body-3">{bank}</label>
                    <label className="li">•</label>
                    <label className="body-3">{condition.quantity} meses</label>
                  </>
                )}
              </span>
              <span className="mb-12">
                <label className="body-3">
                  TNA {formaterNumber(condition.tna)}% / CFT{' '}
                  {formaterNumber(condition.cft)}%
                </label>
              </span>
              <span className="span-flex-between">
                <label className="subtitle-grey color-g600 mr-10">
                  Gastos de financiación
                </label>
                <label className="subtitle-grey color-g600 nowrap">
                  {formatPrice(financialCosts)}
                </label>
              </span>
            </>
          )}
        </>
      )}
      <span
        className={[
          'span-flex-between ',
          (!isMobile || showMobileDetail) && ' border-top',
        ]}
      >
        <label className={isMobile ? classes.cancelAmountText : 'headline-5 mr-10'}>Saldo a cancelar</label>
        <label className={isMobile ? classes.cancelAmount : 'headline-5 nowrap'}>{formatPrice(finalPrice)}</label>
      </span>
    </Box>
  )
}

export default BudgetReservationData
