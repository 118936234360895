import React from 'react'
import { Box, useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Text from '../common/text.component'
import { formaterNumber } from '../../utils/currency.utils'
import Image from '../common/Image.component'
import { CarMedia } from '../car-card/components'
import { capitalizeText } from '../../utils/typography.utils'
import clsx from 'clsx'

const size = 92
const mobileSize = 50

const useStyles = makeStyles((theme) => ({
  square: {
    height: size,
    width: size,
    [theme.breakpoints.down('sm')]: {
      width: mobileSize,
      height: mobileSize,
      marginRight: theme.spacing(1),
    },
  },
  brandContainer: {
    marginBottom: theme.spacing(0.5),
  },
  brand: {
    fontSize: '1.375rem',
    letterSpacing: '-0.16px',
    lineHeight: 1.4,
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  versionBox: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
    },
  },
  version: {
    fontSize: theme.typography.size.small,
    textTransform: 'capitalize',
    lineHeight: 1.4,
    fontWeight: 400,
    marginBottom: theme.spacing(1.25),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    }
  },
  brandModel: {
    maxHeight: size,
    '& > span': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'none',
      marginBottom:'0.25rem',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flexDirection: 'row',
      border: '1px solid',
      borderColor: theme.palette.grayScale.g300,
      borderRadius: '10px',
    },
  },
  brandModelContainer: {
    margin: '0 1.125rem 0.75rem 1.125rem',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  media: {
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '35%',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      minHeight: '7rem',
    },
  },
  yearFuelColor: {
    textTransform: 'capitalize',
    color: theme.palette.grayScale.g400,
  },
  color:{
    textTransform: 'capitalize',
    color: theme.palette.grayScale.g400,
    [theme.breakpoints.down('sm')]: {
      whiteSpace:'nowrap'
    }
  },
  patent: {
    marginLeft: '8px',
    background: '#E8E8E8',
    borderRadius: '5px',
    color: theme.palette.primary.dark,
    padding: '2px 8px',
    fontSize: '12px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  noPhoto: {
    height: size,
    width: size,
    backgroundColor: theme.palette.grayScale.g100,
    border: `1px solid ${theme.palette.grayScale.g400}`,
  },
  infoDetail: {
    marginTop: '2.6rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.625rem',
      width: '65%',
    },
  },
}))


const VehicleInfo = ({ car, logo, selectedStock, mobile, classes = {} }) => {
  const classNames = useStyles()

  const brand = (car.brand.name ?? '').toLowerCase()
  const model = (car.model.name ?? '').toLowerCase()
  const version = (car.version.name ?? '').toLowerCase()
  const observation = capitalizeText(selectedStock.observation ?? '')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileView = mobile !== undefined ? mobile : isMobile

  return (
    <>
      <Box id="info-card-container" className={clsx(classNames.infoContainer, classes?.infoContainer)}>
        <Box id="info-photo" className={clsx(classNames.media, classes?.media)}>
          <CarMedia
            aditionalPhotoClass={classes?.photo}
            photos={car.images.length > 0 ? [car.images[0]] : []}
            onDrag={(_isDragging) => {}}
          />
        </Box>
        <Box id="info-detail" className={clsx(classNames.infoDetail, classes?.infoDetail)} display="flex">
          {!isMobileView && logo && (
            <Box id="info-brand-icon" display="flex" alignItems="center">
              <BrandIcon logo={logo} />
            </Box>
          )}
          <Box id="info-data" display="flex">
            <Box id="info-container" className={classNames.brandModelContainer}>
              <Box
                id="info-bran-model"
                display="flex"
                flexDirection="column"
                className={classNames.brandModel}
              >
                <Box className={clsx(classNames.brandContainer, classes?.brandContainer)}>
                  <Text
                    bold
                    className={classNames.brand}
                  >{`${brand} ${model}`}</Text>
                </Box>
                <Box display='flex' flexWrap='wrap'>
                  <Box className={classNames.versionBox}>
                    <Text className={classNames.version}>{version}</Text>
                    {selectedStock.observation && (
                      <Text className={classNames.patent}>{observation}</Text>
                    )}
                  </Box>
                </Box>
                <Text className={clsx(classNames.yearFuelColor, classes?.yearFuelColor)}>
                  {!car.is0km && car.year ? `${car.year} • ` : null}
                  {car.is0km ? ' 0 Km • ' : ` ${formaterNumber(car.km)} Km • `}
                  {car.fuel && car.fuel.toLowerCase() + ' • '}
                  {selectedStock.color && (
                    <Text className={classNames.color}>{selectedStock.color.name.toLowerCase()}</Text>)}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const BrandIcon = ({ logo }) => {
  const classes = useStyles()
  return logo ? (
    <Image image={logo} className={classes.square} />
  ) : (
    <Box className={classes.noPhoto} />
  )
}

export default VehicleInfo
