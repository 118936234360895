import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import BudgetBackButton from '../../components/budget/BudgetBackButton.component';
import FinancingLineChange from '../../components/financing-line/FinancingLineChange.component';
import FinancingLineResume from '../../components/financing-line/FinancingLineResume.component';
import Button from '../../components/common/button.component';
import CardCreditLine from '../../components/financing-line/CardCreditLine.component';
import Text from '../../components/common/text.component';
import { useAppContext } from '../../context/AppContext';
import { goToFinancingLines, goToPresupuesto } from '../../api/navigation';
import { BUDGET } from '../../utils/constants';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GET_CREDIT_LINES } from '../../queries/getCreditLines';
import LoadingModal from '../../components/common/LoadingModal.component';
import { GET_CFT_QUOTE_AVG } from '../../queries/getCftQuoteAvg';
import dispatchSetCreditLine from '../../context/budget/actions/setCreditLine';



const BACK_TEXT = 'Volver al presupuesto';
const useStyles = makeStyles(theme =>({
  title:{
    fontWeight: 700,
    fontSize: '30px',
    [theme.breakpoints.down('sm')]:{
      marginTop: '5rem',
    }
  },
  marginsCard:{
    width: '100%',
    marginLeft: '25rem',
    marginRight: '20rem',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      display: 'flex',
      padding: '0, 1rem, 0, 1rem',
      flexDirection: 'column',
      marginLeft: '2rem',
      marginRight: '2rem'
    }
  },
  buttonSubmit: {
    minWidth: '12.3125rem',
    minHeight: '3rem',
    marginBottom: '6rem',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
    }
  },
  buttonSize:{
    display: 'flex',
    justifyContent: 'end',
    marginTop: '2.5rem',
  }


}));

const FinancingLineInfo = ({ routeMode })=> {
  const classes = useStyles();
  const { budget, budgetDispatch } = useAppContext();
  const { creditLine: selectedCreditLine, car } = budget;

  const [creditLine, setCreditLine] = useState(selectedCreditLine);
  const [amountToFinance, setAmountToFinance] = useState(
    selectedCreditLine.financedAmount
      ? selectedCreditLine.financedAmount
      : creditLine.condition.capMax
  );
  const [error, setError] = useState(null);

  const { loading: loadingCreditLines, data: creditLinesResponse } = useQuery(GET_CREDIT_LINES, {
    variables: {
      carLotId: car.carLot?.id,
      brandId: car.brand?.id?.[0],
      modelId: car.model?.id?.[0],
      vehicleType: car.is0km ? BUDGET.NEW_CAR_TYPE : BUDGET.USED_CAR_TYPE,
      catalogId: car.id,
      manufactureYear: car.is0km ? null : car.year,
      sortBy: BUDGET.CREDIT_LINE_SORT.QUOTE_QUANTITY_ASC
    },
    // Se agrega la politica network only para que siempre
    // pase por el ciclo de vida completo y actualice la vista
    fetchPolicy: 'network-only'
  });

  const [getDataCftQuoteAvg, { data: cftQuoteAvgResponse }] = useLazyQuery(GET_CFT_QUOTE_AVG, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      avgQuoteValue: creditLine.condition.valQuoteProm,
      calculateAvgQuote: true,
      credit_line_id: creditLine.id,
      montoFinanciar: Number(amountToFinance),
      quantity: creditLine.condition.quantity,
      tna: creditLine.condition.tna,
      valorPrimeraCuota: creditLine.condition.valorPrimeraCuota,
    },
  });
  if(loadingCreditLines){
    return (<LoadingModal/>);
  }

  const { getCreditLines } = creditLinesResponse;
  const conditions = getCreditLines?.find(creditLine => creditLine.id === selectedCreditLine.id)?.lines;

  const onClickEditCreditLine = () => {
    goToFinancingLines(routeMode);
  };

  const addFinancedAmountToBudget = () => {
    if(!error) {
      const newCreditLine = {
        ...creditLine,
        financedAmount: Number(amountToFinance),
        firstQuoteValue: cftQuoteAvgResponse.getCftQuoteAvg.valorPrimeraCuota,
        avgQuoteValue:cftQuoteAvgResponse.getCftQuoteAvg.coutaProm
      };
      dispatchSetCreditLine(newCreditLine, creditLine.condition, budgetDispatch);
      goToPresupuesto(budget.car);
    }
  };

  return (
     <>
     {/* <BudgetBackButton text={BACK_TEXT} routeMode={routeMode}/> */}
      <Grid className={classes.marginsCard}>
      <Text className={classes.title}>Simulá tu crédito</Text>
        <Grid >
          <CardCreditLine showEdit={getCreditLines?.length > 1}
            condition={creditLine?.condition}
            creditLine={creditLine}
            onClickEdit={onClickEditCreditLine}
          />
        </Grid>
        <Grid>
          <FinancingLineChange bold
            creditLine={creditLine}
            conditionsCreditLine={conditions}
            setCreditLine={setCreditLine}
            getDataCftQuoteAvg={() => getDataCftQuoteAvg()}
            amountToFinance={amountToFinance}
            setAmountToFinance={setAmountToFinance}
            error={error}
            setError={setError}
          />
        </Grid>
        <Grid>
          <FinancingLineResume dataQuota={cftQuoteAvgResponse} creditLine={creditLine} bold error={error}/>
        </Grid>
        <div className={classes.buttonSize}>
          <Button
            className={classes.buttonSubmit}
            onClick={addFinancedAmountToBudget}
            primary
          >
        Sumar al presupuesto
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default FinancingLineInfo;
