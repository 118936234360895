import React from 'react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { GET_CITIES, GET_PROVINCES } from '../../queries';
import { ID_ARG } from '../../utils/constants';
import { formatCuit } from '../../utils/validators.utils';
import MaskedTextField from '../../components/common/MaskedTextField.component';
import TextField from '../../components/common/TextField.component';
import AutocompleteSelect from '../../components/common/AutocompleteSelect.component';
import { formatPrice } from '../../utils/currency.utils';
import clsx from 'clsx';
import useStyle from './BudgetClientForm.style';
import SaleCarTitleStep from '../../components/common/SaleCarTitleStep.component';

const LABEL_FIRST_NAME = 'Nombre';
const LABEL_LAST_NAME = 'Apellido';
const LABEL_EMAIL = 'E-mail';
const LABEL_PHONE = 'Teléfono';
const PHONE_MAX_LENGTH = 10;
const DEFAULT_TEXT_MASK =  new Array(15).fill(/[A-Za-z]/);
const PHONE_HELPER_TEXT = 'Ingresá tu número sin el 0 y sin el 15.';
const TITLE_WITH_PAY = 'Tus datos';
const TITLE_WITHOUT_PAY = '¡Qué bueno que quieras reservarlo!';
const SUBTITLE_WITHOUT_PAY = 'Envianos tus datos y nos comunicaremos con vos.';



const BudgetClientFormComponent = ({
  onTextFieldChange,
  fields,
  errorMessages,
  labelEmail = LABEL_EMAIL,
  lablePhone = LABEL_PHONE,
  disableFields = false,
  phoneMaxLength = PHONE_MAX_LENGTH,
  nameMask = null,
  minPrice = 0,
  maxPrice = 0,
  validateAmount,
  isResaleAndWithoutPay,
}) => {
  const [provinces, setProvinces] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedProvince, setSelectedProvince] = React.useState(null);

  const TEXT_MASK = nameMask || DEFAULT_TEXT_MASK;
  const PHONE_MASK =  new Array(phoneMaxLength).fill(/\d/);
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mapDropdown = (elem) => ({
    label: elem.nombre,
    key: elem.id,
    value: { name: elem.nombre, id: [elem.id] },
  });

  const { data: dataProvinces, loading: loadingProvinces } = useQuery(GET_PROVINCES, {
    variables: { idCountry: ID_ARG },
    onCompleted() {
      const prov = dataProvinces.provinces.map(mapDropdown);
      setProvinces(prov);
    },
  });

  const { data: dataCities, loading: loadingCities, refetch } = useQuery(GET_CITIES, {
    skip: !selectedProvince,
    variables: { idCountry: ID_ARG, idProvince: selectedProvince },
    onCompleted() {
      if (dataCities) {
        const cities = dataCities.cities.map(mapDropdown);
        setCities(cities);
      }
    },
  });

  React.useEffect(() => {
    if (!refetch) return;
    onTextFieldChange('city', null);
    setCities([]);
    selectedProvince && refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvince]);

  const handleScroll = (id) => {
    if(!isMobile) return;
    const element = document.getElementById(id);
    const rect = element.getBoundingClientRect();
    //Sin el timeout, no da tiempo a calcular el scroll excluyendo al teclado, y scrollea de mas.
    setTimeout(()=>{window.scrollBy({ left: 0, top: rect.top - rect.height * 1.5 - 160, behaviour: 'smooth' });},500);
  };

  const validateCuit = (value) => {
    const cuitRegex = /^\d{0,2}-?\d{0,8}-?\d{0,1}$/;
    return cuitRegex.test(value);
  };

  const handleOnChangeCuit = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    const isValid = validateCuit(value);
    if (isValid || value === '') {
      const formattedValue = formatCuit(value);
      onTextFieldChange('cuit', formattedValue);
    }
  };

  function formatIntegerAmount(value) {
    const numericValue = value.replace(/[^0-9]/g, '');
    if (numericValue === '') {
      onTextFieldChange('amount', '');
    } else {
      const formattedValue = new Intl.NumberFormat('es-AR', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numericValue);
      onTextFieldChange('amount', formattedValue);
    }
  };

  return (
    <Grid className={classes.SaleClientForm} container>
      <Grid xs={12} item>
        {isResaleAndWithoutPay ?
          <SaleCarTitleStep
            title={TITLE_WITHOUT_PAY}
            subTitle={SUBTITLE_WITHOUT_PAY}
            className={classes.saleCarTitleStep}
          />
          :
          <div className={classes.formTitle} >{TITLE_WITH_PAY}</div>
        }
      </Grid>
      <Grid xs={12} sm={6} item
        className={classes.mobileWidth}>  {/* clsx({ [classes.firstInputLeft]: !isColumn }, classes.mobileWidth) */}
        <MaskedTextField
          id="input-register-name"
          mask={TEXT_MASK}
          onFocus={()=>handleScroll('input-register-name')}
          error={errorMessages && !errorMessages.name?.valid}
          helperText={errorMessages && errorMessages.name?.error}
          className={classes.inputText}
          data-test-id="input-register-name"
          label={LABEL_FIRST_NAME}
          value={fields.name}
          onChange={(evt) => onTextFieldChange('name', evt.target.value)}
          variant="outlined"
          tabIndex={0}
          disabled={disableFields}
        />
      </Grid>
      <Grid xs={12} sm={6} item className={classes.mobileWidth}>
        <MaskedTextField
          id="input-register-lastName"
          mask={TEXT_MASK}
          onFocus={()=>handleScroll('input-register-lastName')}
          error={errorMessages && !errorMessages.lastName?.valid}
          helperText={errorMessages && errorMessages.lastName?.error}
          className={classes.inputText}
          data-test-id="input-register-lastName"
          label={LABEL_LAST_NAME}
          value={fields.lastName}
          onChange={(evt) => onTextFieldChange('lastName', evt.target.value)}
          variant="outlined"
          tabIndex={1}
          disabled={disableFields}
        />
      </Grid>
      <Grid xs={12} sm={6} item className={classes.mobileWidth}>
        <TextField
          id="input-register-email"
          onFocus={()=>handleScroll('input-register-email')}
          error={errorMessages && !errorMessages.email?.valid}
          helperText={errorMessages && errorMessages.email?.error}
          className={classes.inputText}
          data-test-id="input-register-email"
          label={labelEmail}
          value={fields.email}
          onChange={(evt) => onTextFieldChange('email', evt.target.value)}
          variant="outlined"
          tabIndex={2}
          disabled={disableFields}
          inputProps={{
            maxLength: '60'
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} item className={classes.mobileWidth}>
        <MaskedTextField
          id="input-register-phone"
          onFocus={()=>handleScroll('input-register-phone')}
          mask={PHONE_MASK}
          type="tel"
          error={errorMessages && !errorMessages?.phone?.valid}
          helperText={!disableFields && ((errorMessages && errorMessages?.phone?.error) || PHONE_HELPER_TEXT)}
          className={classes.inputText}
          data-test-id="input-register-phone"
          label={lablePhone}
          value={fields.phone}
          onChange={(evt) => onTextFieldChange('phone', evt.target.value)}
          variant="outlined"
          tabIndex={3}
          placeholder="Ej.: 3511234567"
          disabled={disableFields}
        />
      </Grid>
      { !isResaleAndWithoutPay  &&
      <>
        <Grid xs={12} item className={classes.mobileWidth}>
          <TextField
            id="input-register-cuitcuil"
            onFocus={()=>handleScroll('input-register-cuitcuil')}
            error={ errorMessages?.cuit && !errorMessages.cuit.valid}
            helperText={errorMessages?.cuit?.error}
            className={classes.inputText}
            data-test-id="input-register-cuitcuil"
            label='CUIT/CUIL'
            value={fields.cuit}
            onChange={handleOnChangeCuit}
            variant="outlined"
            tabIndex={4}
            disabled={false}
            inputProps={{
              maxLength: '13',
              autocomplete: 'off',
            }}
          />
        </Grid>
        <Grid xs={12} item>
          <div className={classes.formTitle} >Tu domicilio</div>
        </Grid>
        <Grid xs={12} sm={6} item className={classes.mobileWidth}>
          <TextField
            id="input-register-address"
            onFocus={()=>handleScroll('input-register-address')}
            error={errorMessages?.address && !errorMessages.address.valid}
            helperText={errorMessages?.address?.error}
            className={classes.inputText}
            data-test-id="input-register-address"
            label='Dirección'
            value={fields?.address}
            onChange={(evt) => onTextFieldChange('address', evt.target.value)}
            variant="outlined"
            tabIndex={5}
            disabled={false}
            inputProps={{
              maxLength: '100',
              autocomplete: 'off',
            }}
          />
        </Grid>
        <Grid xs={6} sm={3} item className={classes.mobileWidth}>
          <TextField
            id="input-register-floor"
            onFocus={()=>handleScroll('input-register-floor')}
            className={classes.inputText}
            data-test-id="input-register-floor"
            label='Piso'
            value={fields?.floor}
            onChange={(evt) => onTextFieldChange('floor', evt.target.value)}
            variant="outlined"
            tabIndex={6}
            disabled={false}
            inputProps={{
              maxLength: '11',
              autocomplete: 'off',
            }}
          />
        </Grid>
        <Grid xs={6} sm={3} item className={classes.mobileWidth}>
          <TextField
            id="input-register-apartment"
            onFocus={()=>handleScroll('input-register-apartment')}
            className={classes.inputText}
            data-test-id="input-register-apartment"
            label='Dpto.'
            value={fields?.apartment}
            onChange={(evt) => onTextFieldChange('apartment', evt.target.value)}
            variant="outlined"
            tabIndex={7}
            disabled={false}
            inputProps={{
              maxLength: '11',
              autocomplete: 'off',
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} item className={classes.mobileWidth}>
          <TextField
            id="input-register-country"
            className={classes.inputText}
            label='País'
            value='Argentina'
            variant="outlined"
            disabled={true}
          />
        </Grid>
        <Grid xs={12} sm={6} item className={classes.mobileWidth}>
          <AutocompleteSelect
            id="select-province"
            classNameTextfield={classes.inputText}
            getOptionLabel={(val) => val.label || val}
            error={errorMessages?.province && !errorMessages.province?.valid}
            helperText={errorMessages?.province?.error}
            label="Provincia"
            loading={loadingProvinces}
            className={classes.dropdownInput}
            receivedValue={fields.province ? provinces.find((opt) => opt.key === fields.province).label : ''}
            onChange={(val) => {
              if (!val?.id[0]) {
                setSelectedProvince(null);
                onTextFieldChange('province', null);
              } else {
                setSelectedProvince(val.id[0]);
                onTextFieldChange('province', val.id[0]);
              }
            }}
            onFocus={() => handleScroll('select-province')}
            options={provinces}
            classes={{
              option: classes.optionStyles,
              popper: classes.paperStyles,
              clearIndicator: classes.noClearIcon,
            }}
            disableClearable={false}
            optional
            tabIndex={8}
          />
        </Grid>
        <Grid xs={12} sm={6} item className={classes.mobileWidth}>
          <AutocompleteSelect
            id="select-city"
            classNameTextfield={classes.inputText}
            getOptionLabel={(val) => val.label || val}
            error={errorMessages?.city && !errorMessages.city?.valid}
            helperText={errorMessages?.city?.error}
            label="Localidad"
            loading={loadingCities}
            className={classes.dropdownInput}
            receivedValue={fields.city && cities.length > 0 ? cities.find((opt) => opt.key === fields.city).label : ''}
            onChange={(val) => {
              onTextFieldChange('city', val?.id[0]);
            }}
            onFocus={() => handleScroll('select-city')}
            options={cities || []}
            classes={{
              option: classes?.optionStyles,
              popper: classes?.paperStyles,
              clearIndicator: classes?.noClearIcon,
            }}
            disabled={!fields.province}
            disableClearable={false}
            optional
            tabIndex={9}
          />
        </Grid>
        <Grid xs={12} sm={6} item className={classes.mobileWidth}>
          <TextField
            id="input-register-cp"
            onFocus={()=>handleScroll('input-register-cp')}
            error={errorMessages?.cp && !errorMessages.cp?.valid}
            helperText={errorMessages?.cp?.error}
            className={classes.inputText}
            data-test-id="input-register-cp"
            label='Código postal'
            value={fields?.cp}
            onChange={(evt) => {
              const numericValue = evt.target.value.replace(/[^0-9]/g, '');
              onTextFieldChange('cp', numericValue);
            }}
            variant="outlined"
            tabIndex={10}
            disabled={false}
            inputProps={{
              maxLength: '5',
              autocomplete: 'off',
            }}
          />
        </Grid>
        <Grid xs={12} item>
          <div className={classes.formTitle} >¿Con cuánto querés señar?</div>
        </Grid>
        <Grid xs={12} item className={classes.mobileWidth}>
          <TextField
            id="input-register-amount"
            onFocus={()=>handleScroll('input-register-amount')}
            error={fields?.amount && !validateAmount?.valid}
            helperText={validateAmount?.error || `Desde ${formatPrice(minPrice)} hasta ${formatPrice(maxPrice)}`}
            className={clsx(classes.inputText, classes.inputMoney)}
            data-test-id="input-register-amount"
            label='Monto de la seña'
            value={(fields?.amount)}
            onChange={(evt) => formatIntegerAmount(evt.target.value)}
            variant="outlined"
            disabled={false}
            prefix="$"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </Grid>
      </>
      }
    </Grid>
  );
};

export default BudgetClientFormComponent;
