import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_CAR, GET_BRAND_LOGO, GET_USER_AUTH, GET_CFT_QUOTE_AVG } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import LoadingModal from '../../components/common/LoadingModal.component';
import VehicleInfo from '../../components/budget/VehicleInfo.component';
import BudgetInfo from '../../components/budget/BudgetInfo.component';
import { GET_DELIVERY_COSTS } from '../../queries/getDeliveryCosts';
import { GET_CREDIT_LINES } from '../../queries/getCreditLines';
import { useAppContext } from '../../context/AppContext';
import { SET_CAR_DATA, SET_BACK_BUTTON, SET_DELIVERY_COSTS } from '../../context/budget/constants';
import { goToCarDetail } from '../../api/navigation';
import ModalExitPrompt from '../../components/common/ModalExitPrompt.component';
import { BUDGET } from '../../utils/constants';
import { GET_BUDGET_LEGALS } from '../../queries/getLegalsBudget';
import { navigationRoutes } from '../../api/navigationRoutes.enum';
import setFormHasData from '../../context/budget/actions/setFormHasData';
import setCreditLine from '../../context/budget/actions/setCreditLine';
import { isResellerUser, USER_ROLES } from '../../api/authorization';
import { GET_BUDGET_CAR } from '../../queries/getBudgetCar';

const titleModalPrompt = '¿Confirmás que querés salir?';
const subtitleModalPrompt = 'Si salís, no se guardará la información que cargaste.';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1.25rem 4rem 4rem 4rem',
    }
  },
  desktopDetails: {
    width: '100%',
    maxWidth: '1350px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display:'flex',
      flexDirection: 'column',
      width: '100%',
      padding:'0 1rem 0 1rem',
    },

  },
  vehicleInfo: {
    width: '100%'
  },
  budgetInfo: {
    width: '100%'
  },
  legal: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#717171',
    marginTop: '72px ',
    [theme.breakpoints.down('sm')]: {
      display:'flex',
      flexDirection:'column',
      alignItems:'left',
      margin: '1rem',
      minHeight:'3.938rem',
    },
  }
}));

const BudgetDetailContainer = ({ carId, stockId, location }) => {
  const classes = useStyles();
  const [backButtonBudgetRoute, setBackButtonBudgetRoute] = useState('');
  const [recalculatingPrices, setRecalculatingPrices] = useState(false);
  const [modalClientOpen, setModalClientOpen] = useState(false);
  const { budget, budgetDispatch, logsInForAReservation, user } = useAppContext();
  const { profile: { role_name: roleName} = {} } = user;
  const { car, creditLine, usedCars } = budget;

  const { data: authData, loading: authLoading } = useQuery(GET_USER_AUTH, {
    fetchPolicy: 'network-only'
  });

  const isReseller = isResellerUser(roleName);
  const isLoggedIn = (authData && authData.currentUser !== null) || false;

  const { loading: loadingCar } = useQuery(GET_BUDGET_CAR, {
    skip: authLoading,
    // Se agrega policy network-only para
    // que la request respete la variable skip
    fetchPolicy: 'network-only',
    variables: { catalogId: carId },
    onCompleted: data => {
      const stockPrice = data?.budgetCar?.stock.find(vehicle => vehicle.id === stockId).localResalePrice;

      budgetDispatch({
        type: SET_CAR_DATA,
        data: {
          ...data?.budgetCar,
          selectedStock: stockId,
          vehiclePrice: isReseller ? stockPrice : data?.budgetCar?.localNetSalePrice
        }
      });
    }
  });

  const carDataLoaded = car.id && !loadingCar;
  const stock = car.stock?.find(vehicle => vehicle.id === stockId);

  const { loading: loadingLogo, data: logoResponse } = useQuery(GET_BRAND_LOGO, {
    skip: !carDataLoaded,
    variables: { brand: car?.brand?.name },
  });

  const { loading: loadingCosts, data: costsResponse } = useQuery(GET_DELIVERY_COSTS, {
    skip: !carDataLoaded,
    variables: {
      isFromClient: false,
      clientProvince: '',
      country: stock?.originCountry,
      is0km: car.is0km,
      installments: creditLine.condition?.quantity || 0,
      installmentsValue: Math.trunc(creditLine?.avgQuoteValue) || 0,
      listPrice: car.localListPrice,
      totalPrice: car.vehiclePrice,
      bonus: car.localBonification,
      isPrenda: creditLine?.tipoCreditoID && creditLine?.tipoCreditoID === 2,
    },
    onCompleted: data => {
      budgetDispatch({
        type: SET_DELIVERY_COSTS,
        data: data?.getDeliveryCosts?.costs
      });
    }
  });

  const { loading: loadingCreditLines, data: creditLinesResponse } = useQuery(GET_CREDIT_LINES, {
    skip: !carDataLoaded,
    variables: {
      carLotId: car.carLot?.id,
      brandId: car.brand?.id?.[0],
      modelId: car.model?.id?.[0],
      vehicleType: car.is0km ? BUDGET.NEW_CAR_TYPE : BUDGET.USED_CAR_TYPE,
      catalogId: carId,
      manufactureYear: car.is0km ? null : car.year,
      sortBy: BUDGET.CREDIT_LINE_SORT.MAX_CAPITAL_DESC
    },
  });
  const { loading: loadingbudgetLegals, data: budgetLegalsResponse } = useQuery(GET_BUDGET_LEGALS, {
    skip: !carDataLoaded,
    variables: {
      options: {
        hasTakingCar: Boolean(usedCars.length),
        hasFinancing: Boolean(creditLine.id),
      },
    },
  });

  const { refetch: getAvgQuoteAndTfc } = useQuery(GET_CFT_QUOTE_AVG, { skip: true });

  const recalculateFinancing = useCallback(async () => {
    const resellerPrice = car?.stock?.find(vehicle => vehicle.id === car.selectedStock)?.localResalePrice;
    const particularPrice = car?.localNetSalePrice;
    const differenceInPrices = resellerPrice < particularPrice;
    const existingCreditLine = creditLine?.id && creditLine?.condition?.id;

    if(!differenceInPrices || !existingCreditLine) return;
    const foundCreditLines = creditLinesResponse?.getCreditLines;

    const selectedLine = foundCreditLines?.find(creditLineOption => creditLineOption.id === creditLine.id);
    const selectedCondition = selectedLine?.lines?.find(lineOption => lineOption.id === creditLine.condition.id);

    if(selectedCondition?.capMax >= creditLine?.financedAmount) return;

    // Si el capital maximo es menor al monto financiado anteriormente
    const adaptedSelectedLine = JSON.parse(JSON.stringify(selectedLine));
    adaptedSelectedLine.financedAmount = selectedCondition.capMax;

    setRecalculatingPrices(() => true);
    const averageQuoteresponse = await getAvgQuoteAndTfc({
      avgQuoteValue: selectedCondition.valQuoteProm,
      calculateAvgQuote: true,
      credit_line_id: adaptedSelectedLine.id,
      montoFinanciar: adaptedSelectedLine.financedAmount,
      quantity: selectedCondition.quantity,
      tna: selectedCondition.tna,
      valorPrimeraCuota: selectedCondition.valorPrimeraCuota,
    });

    const averageQuoteData = averageQuoteresponse.data.getCftQuoteAvg;
    adaptedSelectedLine.avgQuoteValue = averageQuoteData.coutaProm;
    adaptedSelectedLine.firstQuoteValue = averageQuoteData.valorPrimeraCuota;

    setCreditLine(adaptedSelectedLine, selectedCondition, budgetDispatch);
    setRecalculatingPrices(() => false);
  }, [car.localNetSalePrice, car.selectedStock, car.stock, creditLine, creditLinesResponse]);

  useEffect(() => {
    if(
      logsInForAReservation.current &&
      creditLinesResponse?.getCreditLines &&
      roleName === USER_ROLES.RESALE
    ) {
      recalculateFinancing();
    }
  },
  // Se deshabilita exhaustive-deps para observar sólo las dependencias del array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isLoggedIn, creditLinesResponse]);

  const handleBackClick = (car) => () => {
    // CONFIRMAR SI ESTA BIEN PUSHEAR UNA NUEVA RUTA
    // O SE DEBE VOLVER EN EL HISTORIAL
      goToCarDetail(car);

  };

  // Se setean el label del boton volver
  // Y su accion al ingresar al componente
  useEffect(() => {
    if (car.id) {
      budgetDispatch({
        type: SET_BACK_BUTTON,
        data: {
          onBackClick: handleBackClick(car),
          backLabel: 'Volver'
        },
      });
      const stockId = car.stock[0].id
      const url = encodeURI(
        `${
          navigationRoutes.PRESUPUESTO.ROOT
        }${car.brand.name.toLowerCase()}/${car.model.name.toLowerCase()}/${car.version.name.toLowerCase()}/?productId=${
          car.id
        }&stockId=${stockId}`
        )
        setBackButtonBudgetRoute(url)
      }
      setFormHasData(budgetDispatch, false)
  }, [car, budgetDispatch, usedCars]);

  const loadingData = (
    !car.id || loadingCar ||
    loadingCreditLines ||
    loadingCosts || loadingLogo ||
    loadingbudgetLegals ||
    recalculatingPrices
  );
  return (
    !loadingData ?
      <Box display="flex" flexDirection="row" className={classes.root} id="detalle-container">
        <>
          <Box display="flex" className={classes.desktopDetails} id="desktop-detail">
            <Box className={classes.vehicleInfo} id="vehicle-info">
              <VehicleInfo car={car} selectedStock={stock} logo={logoResponse?.brandLogo} />
            </Box>
            <Box className={classes.budgetInfo} id="budget-info">
              <BudgetInfo
                price={car.vehiclePrice}
                deliveryCosts={costsResponse.getDeliveryCosts?.costs}
                is0km={car.is0km}
                creditLines={creditLinesResponse?.getCreditLines}
                usedCars={usedCars}
                setModalClientOpen={setModalClientOpen}
                modalClientOpen={modalClientOpen}
                isLoggedIn={isLoggedIn}
                location={location}
              />
            </Box>
          </Box>
          <Box className={classes.legal}>
            {budgetLegalsResponse.getBudgetLegals.map(text => <div>{text}</div>)}
          </Box>
        </>
        <Grid
          className={classes.container}
          container
          direction="column"
          spacing={1}
        />
      </Box> : <LoadingModal />
  );
};

BudgetDetailContainer.propTypes = {
  carId: PropTypes.string.isRequired,
};


export default BudgetDetailContainer;
