import gql from 'graphql-tag';

export const SEND_BUDGET = gql`
  mutation SendBudget($clientData: ClientData, $templateData: TemplateData, $budget: Budget, $options: LegalOptions, $emailType:String) {
    sendBudget(clientData: $clientData, templateData: $templateData, budget: $budget, options: $options, emailType: $emailType) {
      success
      url,
      budgetId
    }
  }`;
