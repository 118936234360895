import React, { useState } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Link from './Link.component';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from './radio.component';
import Text from './text.component';
import Button from './button/Button.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const usesStyle = makeStyles((theme) => ({
  root: {
    marginTop: 0,
  },
  moreLessOptionsContainer: {
    position: 'absolute',
    right: 0,
    height: '2rem',
    display: 'flex'
  },
  moreLessOptionsArrow: {
    height: '21px'
  },
  options: {
    width: '100%',
    display: 'flex',
  },
  optionLayout: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiLink-root': {
        paddingLeft: '2rem',
        marginTop: '-12px',
      }
    }
  },
})
);

export default function RadioButtonsGroup({
  name,
  value,
  onChange,
  heading,
  options,
  noOptionsMessage,
  itemsToShow }) {
  const classes = usesStyle();
  return (
    <FormControl component="fieldset" className={classes.options}  classes={{
      root: classes.root,
    }}>
      { heading && <FormLabel component="legend">{heading}</FormLabel> }
      <RadioGroup aria-label={heading} name={name} value={value} onChange={onChange}>
        {
          options.length > 0
            ? <OptionsContainer
              options={options}
              itemsToShow={itemsToShow}
              classes={classes}/>
            : <Text light smaller>{noOptionsMessage}</Text>
        }
      </RadioGroup>
    </FormControl>
  );
}

const SEE_ALL_TEXT = 'Más opciones';
const SEE_LESS_TEXT = 'Menos opciones';

const OptionsContainer = ({ options, itemsToShow, classes = {} }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let displayOptions = options;
  const hasOverflow = itemsToShow && itemsToShow < options.length;

  if(hasOverflow && !isExpanded){
    displayOptions = options.slice(0,itemsToShow);
  }
  const changeIsExpanded = () => {
    setIsExpanded(!isExpanded);
    displayOptions = options;
  };

  const linkText = isExpanded ? SEE_LESS_TEXT : SEE_ALL_TEXT;

  return (
    <>
      <Options options={displayOptions} classes={classes} className={classes.options}/>

      { hasOverflow &&
      <Box textAlign='center'>
        <Link inverted onClick={() => changeIsExpanded()}>
          <div className={classes.moreLessOptionsContainer}>
            <span className={classes.moreLessOptionsContent}>
              {linkText}
            </span>
            {!isExpanded ? 
              <ExpandMoreIcon className={clsx(classes.moreLessOptionsArrow, classes.moreLessOptionsContent)}/>
              : 
              <ExpandLessIcon className={clsx(classes.moreLessOptionsArrow, classes.moreLessOptionsContent)}/>
            }
          </div>
        </Link>
      </Box>
      }
    </>
  );
};

const Options = ({ options, classes }) => {
  return options.map(option => (
    <Box className={classes.optionLayout}>
      <Radio key={option.value} label={option.label} value={option.value} />
      {
        option.action
          ? <OptionAction action={option.action}/>
          : null
      }
    </Box>
  ));
};

const OptionAction = ({ action }) => {
  return (
    <Link href={action.link} target="_blank">
      <Button type='link'>{action.text}</Button>
    </Link>
  )
}
