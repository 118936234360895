import React from 'react';
import {
  ERROR_BUDGET,
  ERROR_RESERVATION,
  LOADING_BUDGET,
  LOADING_RESERVATION
} from '../../../containers/budget/constants';
import BudgetErrorModal from './BudgetErrorModal';
import BudgetErrorReservationModal from './BudgetErrorReservationModal';
import BudgetLoadingModal from './BudgetLoadingModal';
import BudgetLoadingReservationModal from './BudgetLoadingReservationModal';

const BudgetControllerModal = ({
  openModal = false,
  setOpenModal = () => {},
  state = 0
}) => {

  if(!openModal){
    return <></>;
  }

  switch (state) {
    case LOADING_BUDGET:
      return <BudgetLoadingModal
        setOpenModal = {setOpenModal}/>;
    case ERROR_BUDGET:
      return <BudgetErrorModal
        setOpenModal = {setOpenModal}/>;
    case ERROR_RESERVATION:
      return <BudgetErrorReservationModal
        setOpenModal = {setOpenModal}/>;
    case LOADING_RESERVATION:
      return <BudgetLoadingReservationModal
        setOpenModal = {setOpenModal}/>;
    default:
      return <></>;
  }

};

export default BudgetControllerModal;
