import { makeStyles } from '@material-ui/styles';
import React from 'react';
import ResponsiveStatusModal from '../../common/ResponsiveStatusModal.component';
import {
  MESSAGE_ERROR_RESERVATION,
  MESSAGE_LEYEND_ERROR_RESERVATION
} from '../../../containers/budget/constants';
import { navigate } from 'gatsby';


const useStyles = makeStyles((theme) => ({
  containerLoading: {
    paddingTop:'3.75rem',
    paddingBottom:'3.75rem',
    '& > #close_button_succes, #icon_succes':{
      display:'none'
    },
    '& > * #succes_btn_primary':{
      marginTop:'12px',
      width: '296px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2.8rem',
    },
    
  },
  
}));


const BudgetErrorReservationModal = ({ isMobile }) => {

  const handlePrimaryButtonClick = () => {
    // Se redirecciona a la url hardcodeada
    // Porque la ruta de navigation.js no responde
    navigate('/catalogo');
  };

  const classes = useStyles();
  return (
    <ResponsiveStatusModal
      loading={false}
      success={true}
      isMobile={isMobile}
      open={true}
      successMessage={MESSAGE_ERROR_RESERVATION}
      successLegend={MESSAGE_LEYEND_ERROR_RESERVATION}
      primarySuccessTextBtn={'Ok'}
      onPrimarySuccessClick={handlePrimaryButtonClick}
      containerClassName={classes.containerLoading}
    />
  );
};

export default BudgetErrorReservationModal;
