import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SaleCarForm from '../../components/sell-your-car/SaleCarForm.component'
import SaleCarTitleStep from '../../components/common/SaleCarTitleStep.component'
import { Grid, makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  sellYourCarForm: {
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
      '& [data-test-id="boton-cotizar"]':{
        width: '100%',
      }
    },
    [theme.breakpoints.up('sm')]: {
      width: '38.5rem',
    },
    textAlign: 'center',
    margin: '0 auto',
  },
  saleCarTitleStep:{
    '& #title':{
      fontWeight: 700,
      fontSize: '1.875rem',
      marginBottom: '0.5rem'
    }
  }
}))

const SaleCarFormContainer = ({
  sellYourCarButton,
  saleCarFormTitle,
  saleCarFormSubtitle = '',
  onNextStep,
  onSaveCarData,
  onFormEmpty,
  previousQuote,
  clearAllFields,
  addToBudget
}) => {
  const classes = useStyle()

  const onGetQuote = (quoteData) => {
    const carData = {
      brand: {
        id: quoteData.vehicle.brand,
        name: quoteData.vehicle.brandName ?? previousQuote.brand.name,
      },
      model: {
        id: quoteData.vehicle.model,
        name: quoteData.vehicle.modelName ?? previousQuote.model.name,
      },
      version: {
        id: quoteData.vehicle.version,
        name: quoteData.vehicle.versionName ?? previousQuote.version.name,
      },
      year: quoteData.vehicle.year,
      mileage: Number(quoteData.kms),
      isKmsCalculated: quoteData.isKmsCalculated,
      condition: quoteData.interior,
      tipo_cotizador: quoteData.vehicle.tipo_cotizador
    }

    onSaveCarData(carData)
    onNextStep()
  }

  return (
    <Grid className={classes.sellYourCarForm} container direction="column">
      <Grid item>
        <SaleCarTitleStep
          title={saleCarFormTitle}
          subTitle={saleCarFormSubtitle}
          className={classes.saleCarTitleStep}
        />
      </Grid>
      <Grid item>
        <SaleCarForm
          sellYourCarButton={sellYourCarButton}
          onGetQuote={onGetQuote}
          onFormEmpty={onFormEmpty}
          previousQuote={previousQuote}
          clearAllFields={clearAllFields}
          addToBudget={addToBudget}
        />
      </Grid>
    </Grid>
  )
}

SaleCarFormContainer.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onSaveCarData: PropTypes.func.isRequired,
}

export default SaleCarFormContainer
