export const TYPE_CAR_UTILITARIO = 'Utilitario';
export const TYPE_CAR_AUTO= 'Autos';
export const CAR_KMS_MULTIPLIER = 15000;
export const PICKUP_KMS_MULTIPLIER = 20000;

export const calculateKms = (kilometers,  year, tipo) => {
  if (kilometers && kilometers !== '') return kilometers;
  const carTypes = {
    utilitario: TYPE_CAR_UTILITARIO,
    auto: TYPE_CAR_AUTO
  };
  const yearNow = new Date().getFullYear();

  const antiquity = (yearNow === year) ? 1 : yearNow - year;

  if (tipo === carTypes.auto) return antiquity * CAR_KMS_MULTIPLIER;
  if (tipo === carTypes.utilitario) return antiquity * PICKUP_KMS_MULTIPLIER;
  return null;
};
