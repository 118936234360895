import React from 'react';
import { Divider, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import { formaterNumber, formatFractionDigits } from '../../utils/currency.utils';

const useStyles = makeStyles(theme =>({
  boxContainer:{
    border: '1px',
    borderRadius: '10px',
    background: '#f6f6f6',
    paddingTop: '18px',
    paddingBottom: '18px',
    paddingInline: '22px'
  },
  boxContainerInput:{
    lineHeight:'3rem',
    color:'#5c5c5c',

  },
  boxSummary:{
    display: 'grid',
    lineHeight:'1.7rem',
    fontSize:'16px',
    color: '#5c5c5c',
    marginTop: '10px',
    fontFamily: 'Poppins'
  },
  container:{
    backgroundColor: theme.palette.grayScale.g250,
    borderRadius: '0.625rem',
    '& .span-flex-between': {
      display: 'flex',
      justifyContent: 'space-between',
      '&.border-top': {
        marginTop: '2rem',
        paddingTop: '1.9063rem',
        borderTop: `0.0625rem solid ${theme.palette.grayScale.g700}`,
      },
    },
  },
  textColorPrimary:{
    fontWeight: 'bold',
    fontSize: '18px'
  },
  textColor:{
    fontWeight: 'bold',
    fontSize: '16px'
  }

}));

const currencyFormat = (num) => {
  return '$' + Number(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
};

const FinancingLineResume = ({ dataQuota, creditLine,error }) => {
  const cft = formaterNumber(creditLine.condition.cft) || formaterNumber(dataQuota?.getCftQuoteAvg?.cft);
  const lineFinaceObj = {
    cuoataMensual: currencyFormat(Math.trunc(dataQuota?.getCftQuoteAvg.coutaProm) || 0),
    cuotaInicial: currencyFormat(Math.trunc(dataQuota?.getCftQuoteAvg.valorPrimeraCuota) || 0),
    entidad: creditLine.bank,
    MontoMax: currencyFormat(creditLine.condition.capMax || 0),
    cantidadCuotas: creditLine.condition.quantity,
    Interses: `${formatFractionDigits(creditLine.condition.tna)}% / ${cft}%`
  };

  const classes = useStyles();
  return (
    <>
      <Box className={classes.boxContainer}>
        <span className={classes.container}>
          <span className="span-flex-between subtitle-3 mb-16">
            <label className={classes.textColorPrimary} >Cuota mensual promedio </label>
            <label className={classes.textColorPrimary} >{error ? '$ -' : lineFinaceObj.cuoataMensual}</label>
          </span>
        </span>
        <span className={classes.container}>
          <Box className={classes.boxContainerInput}>
            <span className="span-flex-between subtitle-3 mb-16">
              <label className={classes.textColor}>Cuota inicial</label>
              <label className={classes.textColor}>{error ? '$ -' : lineFinaceObj.cuotaInicial}</label>
            </span>
          </Box>
        </span>
        <Divider/>
        <Box className={classes.boxSummary}>
          <span className={classes.container}>
            <span className="span-flex-between subtitle-3 mb-16">
              <label>Entidad</label>
              <label> {lineFinaceObj.entidad}</label>
            </span>
            <span className="span-flex-between subtitle-3 mb-16">
              <label>Monto máximo permitido</label>
              <label> {lineFinaceObj.MontoMax}</label>
            </span>
            <span className="span-flex-between subtitle-3 mb-16">
              <label>Cantidad de cuotas</label>
              <label> {lineFinaceObj.cantidadCuotas}</label>
            </span>
            <span className="span-flex-between subtitle-3 mb-16">
              <label>Intereses (TNA / CFT)</label>
              <label> {lineFinaceObj.Interses}</label>
            </span>
          </span>
        </Box>
      </Box>
    </>
  );
};

export default FinancingLineResume;
