import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import Layout from '../../components/layouts/layout.component';
import CompletedReservation from '../../components/completed-reservation/CompletedReservation.component';
import IncompletedReservation from '../../components/completed-reservation/IncompletedReservation.component';
import { useLocation } from '@reach/router';


const PAGE_TITLE = 'Reserva'; // TODO: validar
const META_DESCRIPTION = ''; //TODO: validar

const useStyle = makeStyles((theme) => ({
  // Espacio para separar el header
  // que tiene position fixed y solapa el contenido interno
  headerSpacing: {
    marginTop: '4rem',
    [theme.breakpoints.up('md')]: {
      marginTop: '3rem'
    }
  },
  mainContainer: {
    minWidth: '100%',
    minHeight: 'min(41.125rem, 100svh)',
    paddingTop: '1rem',
    paddingLeft: '3.25rem',
    paddingRight: '3.25rem',
    paddingBottom: '3.5rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0',
      paddingRight: '0',
      paddingBottom: '1.5rem',
    }
  }
}));

const CompletedPaymentContainer = ({ locationPrueba, pageContext, carId }) => {
  const classes = useStyle();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stockActive = searchParams.get('stockActive');
  const isStockActive = !!(stockActive && stockActive.toLowerCase() === 'true');
  const withOutPay = searchParams.get('sinPago');
  
  return (
    <Layout
      title={PAGE_TITLE}
      location={locationPrueba}
      pageContext={pageContext}
      showSubHeader={false}
      description={META_DESCRIPTION}
    >
      <Box className={`${classes.headerSpacing} ${classes.mainContainer}`}>
        {withOutPay ? <CompletedReservation/> : 
       isStockActive ? <CompletedReservation/> : <IncompletedReservation/>}
       </Box>
    </Layout>
  );
};


CompletedPaymentContainer.propTypes = {
  requestRegistrationId: PropTypes.string,
};

CompletedPaymentContainer.defaultProps = {
  requestRegistrationId: ''
};

export default CompletedPaymentContainer;
