import React, { useEffect, useState } from 'react';
import QuotationForm from '../../components/quotation/form/QuotationForm.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_MODEL_VERSIONS } from '../../queries/getModelVersions';
import { GET_MODEL_YEARS } from '../../queries/getModelYears';
import { GET_MODELS, GET_BRANDS } from '../../queries';

export default function QuoteFormContainer({
  onGetQuote,
  horizontalForm = true,
  buttonText,
  onFormEmpty = () => {},
  filters: quoteFilters = {},
  clearAllFields,
  addToBudget
}) {

  const [ filters, setFilters ] = useState(quoteFilters);

  const { data: brandData, loading: loadingMarcas } = useQuery(GET_BRANDS, {
    variables: { published: null }
  });

  const { data: modelData, loading: loadingModelos } = useQuery(GET_MODELS, {
    variables: { brandId: filters.brandId, published: null, requiredPrices: true }
  });

  const { data: yearsData, loading: loadingYears } = useQuery(GET_MODEL_YEARS, {
    variables: {
      brandId: filters.brandId,
      modelId: filters.modelId
    }
  });

  const { data: versionsData, loading: loadingVersions } = useQuery(GET_MODEL_VERSIONS, {
    variables: {
      brandId: filters.brandId,
      modelId: filters.modelId,
      year: filters.year
    }
  });

  const validateEmptyForm = (formData) => {
    const isEmpty = !Boolean(formData.brand) && !Boolean(formData.kms)
    onFormEmpty(isEmpty)
  }

  const changeNewFilters = (newFilters) => {
    setFilters(newFilters)
    validateEmptyForm(newFilters)
  }
  useEffect(()=>{
    if(clearAllFields){
      const newFilters = { ...filters };
      delete newFilters['brand'];
      delete newFilters['brandName'];
      delete newFilters['model'];
      delete newFilters['modelName'];
      delete newFilters['modelId'];
      delete newFilters['year'];
      delete newFilters['yearName'];
      delete newFilters['version'];
      delete newFilters['versionName'];
      delete newFilters['kms'];
      changeNewFilters(newFilters);
    }
  },[clearAllFields])
  const handleOnSelectBrand = (brand) => {
    const newFilters = { ...filters };
    newFilters['brand'] = brand.name;
    newFilters['brandName'] = brand.name;
    if (brand.id) newFilters['brandId'] = brand.id[0];
    delete newFilters['model'];
    delete newFilters['modelName'];
    delete newFilters['modelId'];
    delete newFilters['year'];
    delete newFilters['yearName'];
    delete newFilters['version'];
    delete newFilters['versionName'];
    changeNewFilters(newFilters);
  };

  const handleOnSelectModel = (model) => {
    const newFilters = { ...filters };
    newFilters['model'] = model.name;
    newFilters['modelName'] = model.name;
    if (model.id) newFilters['modelId'] = model.id[0];
    delete newFilters['year'];
    delete newFilters['yearName'];
    delete newFilters['version'];
    delete newFilters['versionName'];
    changeNewFilters(newFilters);
  };

  const handleOnSelectYear = (year) => {
    const newFilters = { ...filters };
    newFilters['year'] = year;
    newFilters['yearName'] = year;
    delete newFilters['version'];
    delete newFilters['versionName'];
    changeNewFilters(newFilters);
  };

  const handleOnSelectVersion = (version) => {
    const newFilters = { ...filters };
    newFilters['version'] = version.id;
    newFilters['versionName'] = version.name;
    newFilters['tipo_cotizador'] = version.tipo_cotizador;
    changeNewFilters(newFilters);
  };

  const handleOnChangeKm = (kms) => {
    const newFilters = { ...filters };
    newFilters['kms'] = kms
    changeNewFilters(newFilters);
  };

  const brands = brandData?.brands || [];
  const models = modelData?.models || [];
  const versions = versionsData?.versions || [];
  const years = yearsData?.years || [];

  const previousBrand = brandData && brandData.brands.find(b => b.id[0] === quoteFilters.brandId)
  const previousModel = modelData && modelData.models.find(m => m.id[0] === quoteFilters.modelId)
  const previousVersion = versionsData && versionsData.versions.find(v => v.id === quoteFilters.version)
  const previousQuote = {
    brand: previousBrand,
    model: previousModel,
    version: previousVersion,
  }

  useEffect(() => {
    validateEmptyForm(filters)
  }, [])

  return (
    <QuotationForm
      horizontalForm={horizontalForm}
      buttonText={buttonText}
      filters={filters}
      brands={brands}
      models={models}
      years={years}
      versions={versions}
      loadingMarcas={loadingMarcas}
      loadingModelos={loadingModelos}
      loadingYears={loadingYears}
      loadingVersions={loadingVersions}
      onGetQuote={onGetQuote}
      onSelectBrand={handleOnSelectBrand}
      onSelectModel={handleOnSelectModel}
      onSelectYear={handleOnSelectYear}
      onSelectVersion={handleOnSelectVersion}
      onChangeKm={handleOnChangeKm}
      previousQuote={previousQuote}
      addToBudget={addToBudget}
    />
  );
}
