// Se deshabilita regla de eslint porque es necesario
// usar ref para no depender de la actualizacion de useState
// y un effect con su arreglo sin dependencias para ejecutarse solo al desmontarse

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BudgetContainer from '../containers/budget/Budget.container';
import { useAppContext } from '../context/AppContext';
import BudgetUsedCarSurrenderContainer from '../containers/budget/BudgetUsedCarSurrender.container';
import BudgetSummaryContainer from '../containers/budget/BudgetSummary.container';
import { useTheme } from '@material-ui/core';
import BudgetCreditLinesContainer from '../containers/budget/BudgetCreditLines.container';
import FinanceLinePageContainer from '../containers/financing-line/FinancingLinePage.container';
import { navigationRoutes } from '../api/navigationRoutes.enum';
import ModalExitPrompt from '../components/common/ModalExitPrompt.component';
import { showBudgetExitPrompt } from '../context/modalExitPrompt/utils';
import resetBudgetState  from '../context/budget/actions/resetBudgetState';
import CompletedReservationContainer from '../containers/completed-reservation/CompletedReservation.container';
const titleModalPrompt = '¿Confirmás que querés salir?';
const subtitleModalPrompt = 'Si salís, no se guardará la información que cargaste.';

const BudgetDetailRouter = (props) => {
  const { budget, budgetDispatch, logsInForAReservation } = useAppContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    return () => {
      // Sólo se mantiene el estado si el usuario
      // se está por logear para reservar (logsInForAReservation.current = true)
      if(!logsInForAReservation.current) {
        resetBudgetState(budgetDispatch);
      }
    };
  }, []);

  const showModalExitFunction = (prevRoute, nextRoute, preventExitPrompt) => showBudgetExitPrompt(prevRoute, nextRoute, preventExitPrompt, budget)
  return (
    <>
      <ModalExitPrompt
        title={titleModalPrompt}
        subtitle={subtitleModalPrompt}
        showModal={showModalExitFunction}
      />
      <Router basepath="/presupuesto">
        <BudgetContainer {...props} path="/:brand/:model/:version" />
        <BudgetUsedCarSurrenderContainer
          {...props}
          path="/entrega-auto-usado"
        />
        <FinanceLinePageContainer
          {...props}
          path={`${navigationRoutes.PRESUPUESTO.LINE_DETAILS}/:routeMode`}
        />
        <BudgetSummaryContainer
          {...props}
          isMobile={isMobile}
          path="/resumen/:type"
        />
        <BudgetCreditLinesContainer
          {...props}
          path={`${navigationRoutes.PRESUPUESTO.FINANCING_LINES}/:routeMode`}
        />
        <CompletedReservationContainer {...props} path="/reserva-completada"  />
      </Router>
    </>
  );
};

export default BudgetDetailRouter;
