import React from 'react';
import ResponsiveStatusModal from '../../common/ResponsiveStatusModal.component';

import {
  MESSAGE_ERROR,
  PRIMARY_BTN_ERROR,
  RESERVE_LEYEND_ERROR
} from '../../../containers/budget/constants';

const BudgetErrorModal = ({ setOpenModal, isMobile }) => {
  return (
    <ResponsiveStatusModal
      success={false}
      isMobile={isMobile}
      open={true}
      errorMessage={MESSAGE_ERROR}
      errorLegend={RESERVE_LEYEND_ERROR}
      errorTextBtn={PRIMARY_BTN_ERROR}
      onErrorBtnClick={() => {setOpenModal(false);}}
      onClose={() => {setOpenModal(false);}}
    />
  );
};

export default BudgetErrorModal;
